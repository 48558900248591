
// for tablets (iPadPro, etc. with a max-width equals 1456px width)

@media (max-width: 1456px) {

	// common media styles

	.d-tbt-none {
		display: none !important;
	}

	.d-tbt-block {
		display: block !important;
	}
	.d-tbt-flex {
		display: flex !important;
	}

	.d-tbt-inline-flex {
		display: inline-flex !important;
	}


	.tbt-static {
		position: static;
	}
	.tbt-absolute {
		position: absolute;
	}

	.w-tbt-auto {
		width: auto !important;
	}
	.w-tbt-100 {
		width: 100% !important;
	}
	.max-w-tbt-unset {
		max-width: unset !important;
	}
	.min-w-tbt-unset {
		min-width: unset !important;
	}
	.max-h-tbt-unset {
		max-height: unset !important;
	}
	.min-h-tbt-unset {
		min-height: unset !important;
	}
	.h-tbt-auto {
		height: auto !important;
	}

	.w-tbt-50 {
		width: 50%;
		flex:0 0 50%;
	}

	.w-tbt-33 {
		width: 33.33%;
		flex:0 0 33.33%;
	}

	.w-tbt-25 {
		width: 25%;
		flex:0 0 25%;
	}

	.w-tbt-15 {
		width: 15%;
		flex:0 0 15%;
	}

	.center-tbt {
		text-align: center;
	}

	.o-tbt-initial {
		overflow: initial;
	}



	// flex-related settings

	.f-tbt-1 {
		flex:1;
	}
	.flex-tbt-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-tbt-column {
		flex-direction: column !important;
	}
	.flex-tbt-row {
		flex-direction: row !important;
	}
	.flex-tbt-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-tbt-wrap {
		flex-wrap: wrap;
	}

	.justify-content-tbt-start {
		justify-content: flex-start !important;
	}
	.justify-content-tbt-between {
		justify-content: space-between !important;
	}

	.align-items-tbt-start {
		align-items: start !important;
	}


	.m-tbt-0 {
		margin: 0 !important;
	}
	.mt-tbt-0 {
		margin-top: 0 !important;
	}
	.mr-tbt-0 {
		margin-right: 0 !important;
	}
	.mb-tbt-0 {
		margin-bottom: 0 !important;
	}
	.ml-tbt-0 {
		margin-left: 0 !important;
	}
	.m-tbt-1 {
		margin: 0.25rem !important;
	}
	.mt-tbt-1 {
		margin-top: 0.25rem !important;
	}
	.mr-tbt-1 {
		margin-right: 0.25rem !important;
	}
	.mb-tbt-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-tbt-1 {
		margin-left: 0.25rem !important;
	}
	.m-tbt-2 {
		margin: 0.5rem !important;
	}
	.mt-tbt-2 {
		margin-top: 0.5rem !important;
	}
	.mr-tbt-2 {
		margin-right: 0.5rem !important;
	}
	.mb-tbt-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-tbt-2 {
		margin-left: 0.5rem !important;
	}
	.m-tbt-3 {
		margin: 1rem !important;
	}
	.mt-tbt-3 {
		margin-top: 1rem !important;
	}
	.mr-tbt-3 {
		margin-right: 1rem !important;
	}
	.mb-tbt-3 {
		margin-bottom: 1rem !important;
	}
	.ml-tbt-3 {
		margin-left: 1rem !important;
	}
	.m-tbt-4 {
		margin: 1.5rem !important;
	}
	.mt-tbt-4 {
		margin-top: 1.5rem !important;
	}
	.mr-tbt-4 {
		margin-right: 1.5rem !important;
	}
	.mb-tbt-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-tbt-4 {
		margin-left: 1.5rem !important;
	}
	.m-tbt-5 {
		margin: 3rem !important;
	}
	.mt-tbt-5 {
		margin-top: 3rem !important;
	}
	.mr-tbt-5 {
		margin-right: 3rem !important;
	}
	.mb-tbt-5 {
		margin-bottom: 3rem !important;
	}
	.ml-tbt-5 {
		margin-left: 3rem !important;
	}
	.p-tbt-0 {
		padding: 0 !important;
	}
	.pt-tbt-0 {
		padding-top: 0 !important;
	}
	.pr-tbt-0 {
		padding-right: 0 !important;
	}
	.pb-tbt-0 {
		padding-bottom: 0 !important;
	}
	.pl-tbt-0 {
		padding-left: 0 !important;
	}
	.p-tbt-1 {
		padding: 0.25rem !important;
	}
	.pt-tbt-1 {
		padding-top: 0.25rem !important;
	}
	.pr-tbt-1 {
		padding-right: 0.25rem !important;
	}
	.pb-tbt-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-tbt-1 {
		padding-left: 0.25rem !important;
	}
	.p-tbt-2 {
		padding: 0.5rem !important;
	}
	.pt-tbt-2 {
		padding-top: 0.5rem !important;
	}
	.pr-tbt-2 {
		padding-right: 0.5rem !important;
	}
	.pb-tbt-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-tbt-2 {
		padding-left: 0.5rem !important;
	}
	.p-tbt-3 {
		padding: 1rem !important;
	}
	.pt-tbt-3 {
		padding-top: 1rem !important;
	}
	.pr-tbt-3 {
		padding-right: 1rem !important;
	}
	.pb-tbt-3 {
		padding-bottom: 1rem !important;
	}
	.pl-tbt-3 {
		padding-left: 1rem !important;
	}
	.p-tbt-4 {
		padding: 1.5rem !important;
	}
	.pt-tbt-4 {
		padding-top: 1.5rem !important;
	}
	.pr-tbt-4 {
		padding-right: 1.5rem !important;
	}
	.pb-tbt-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-tbt-4 {
		padding-left: 1.5rem !important;
	}
	.p-tbt-5 {
		padding: 3rem !important;
	}
	.pt-tbt-5 {
		padding-top: 3rem !important;
	}
	.pr-tbt-5 {
		padding-right: 3rem !important;
	}
	.pb-tbt-5 {
		padding-bottom: 3rem !important;
	}
	.pl-tbt-5 {
		padding-left: 3rem !important;
	}
	.m-tbt-auto {
		margin: auto !important;
	}
	.mt-tbt-auto {
		margin-top: auto !important;
	}
	.mr-tbt-auto {
		margin-right: auto !important;
	}
	.mb-tbt-auto {
		margin-bottom: auto !important;
	}
	.ml-tbt-auto {
		margin-left: auto !important;
	}

	// font-sizes

	p.fs-20, div.fs-20, span.fs-20 {
	  font-size: 16px;
	}

	.fs-25, .font_size_xxl, .fs-24, .fs-26 {
	  font-size: 20px !important;
	}

	.fs-35 {
	  font-size: 30px;
	}
	
	.lh-2 {line-height: 25px}

}


// 

@media (max-width: 1024px) {
	.flex-tbts-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-tbts-wrap {
		flex-wrap: wrap;
	}

	.mb-tbts-0 {
		margin-bottom: 0 !important;
	}
	.mb-tbts-3 {
		margin-bottom: 1rem !important;
	}

	.w-tbts-100 {
		width: 100% !important;
	}
}

//

@media all and (max-width: 960px) {
		// common media styles

	.w-mobile-75 {
		width: 75% !important;
	}

	.w-mobile-40 {
		width: 40%;
	}

	.d-mobile-none {
		display: none !important;
	}

	.d-mobile-block {
		display: block !important;
	}
	.d-mobile-flex {
		display: flex !important;
	}

	.d-mobile-inline-flex {
		display: inline-flex !important;
	}


	.mobile-static {
		position: static;
	}
	.mobile-absolute {
		position: absolute;
	}

	.w-mobile-auto {
		width: auto !important;
	}
	.w-mobile-100 {
		width: 100% !important;
		min-width: unset;
    	max-width: unset;
	}
	.max-w-mobile-unset {
		max-width: unset !important;
	}
	.min-w-mobile-unset {
		min-width: unset !important;
	}
	.max-h-mobile-unset {
		max-height: unset !important;
	}
	.min-h-mobile-unset {
		min-height: unset !important;
	}
	.h-mobile-auto {
		height: auto !important;
	}

	.w-mobile-50 {
		width: 50% !important;
		flex:0 0 50%;
	}

	.w-mobile-33 {
		width: 33.33%;
		flex:0 0 33.33%;
	}

	.w-mobile-25 {
		width: 25%;
		flex:0 0 25%;
	}

	.w-mobile-15 {
		width: 15%;
		flex:0 0 15%;
	}

	.center-mobile {
		text-align: center;
	}

	.o-mobile-initial {
		overflow: initial;
	}



	// flex-related settings

	.f-mobile-1 {
		flex:1;
	}
	.f-mobile-100 {
		flex: 100%;
	}
	.flex-mobile-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-mobile-column {
		flex-direction: column !important;
	}
	.flex-mobile-row {
		flex-direction: row !important;
	}
	.flex-mobile-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-mobile-wrap {
		flex-wrap: wrap;
	}

	.justify-content-mobile-start {
		justify-content: flex-start !important;
	}
	.justify-content-mobile-between {
		justify-content: space-between !important;
	}

	.align-items-mobile-start {
		align-items: start !important;
	}


	.m-mobile-0 {
		margin: 0 !important;
	}
	.mt-mobile-0 {
		margin-top: 0 !important;
	}
	.mr-mobile-0 {
		margin-right: 0 !important;
	}
	.mb-mobile-0 {
		margin-bottom: 0 !important;
	}
	.ml-mobile-0 {
		margin-left: 0 !important;
	}
	.m-mobile-1 {
		margin: 0.25rem !important;
	}
	.mt-mobile-1 {
		margin-top: 0.25rem !important;
	}
	.mr-mobile-1 {
		margin-right: 0.25rem !important;
	}
	.mb-mobile-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-mobile-1 {
		margin-left: 0.25rem !important;
	}
	.m-mobile-2 {
		margin: 0.5rem !important;
	}
	.mt-mobile-2 {
		margin-top: 0.5rem !important;
	}
	.mr-mobile-2 {
		margin-right: 0.5rem !important;
	}
	.mb-mobile-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-mobile-2 {
		margin-left: 0.5rem !important;
	}
	.m-mobile-3 {
		margin: 1rem !important;
	}
	.mt-mobile-3 {
		margin-top: 1rem !important;
	}
	.mr-mobile-3 {
		margin-right: 1rem !important;
	}
	.mb-mobile-3 {
		margin-bottom: 1rem !important;
	}
	.ml-mobile-3 {
		margin-left: 1rem !important;
	}
	.m-mobile-4 {
		margin: 1.5rem !important;
	}
	.mt-mobile-4 {
		margin-top: 1.5rem !important;
	}
	.mr-mobile-4 {
		margin-right: 1.5rem !important;
	}
	.mb-mobile-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-mobile-4 {
		margin-left: 1.5rem !important;
	}
	.m-mobile-5 {
		margin: 3rem !important;
	}
	.mt-mobile-5 {
		margin-top: 3rem !important;
	}
	.mr-mobile-5 {
		margin-right: 3rem !important;
	}
	.mb-mobile-5 {
		margin-bottom: 3rem !important;
	}
	.ml-mobile-5 {
		margin-left: 3rem !important;
	}
	.p-mobile-0 {
		padding: 0 !important;
	}
	.pt-mobile-0 {
		padding-top: 0 !important;
	}
	.pr-mobile-0 {
		padding-right: 0 !important;
	}
	.pb-mobile-0 {
		padding-bottom: 0 !important;
	}
	.pl-mobile-0 {
		padding-left: 0 !important;
	}
	.p-mobile-1 {
		padding: 0.25rem !important;
	}
	.pt-mobile-1 {
		padding-top: 0.25rem !important;
	}
	.pr-mobile-1 {
		padding-right: 0.25rem !important;
	}
	.pb-mobile-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-mobile-1 {
		padding-left: 0.25rem !important;
	}
	.p-mobile-2 {
		padding: 0.5rem !important;
	}
	.pt-mobile-2 {
		padding-top: 0.5rem !important;
	}
	.pr-mobile-2 {
		padding-right: 0.5rem !important;
	}
	.pb-mobile-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-mobile-2 {
		padding-left: 0.5rem !important;
	}
	.p-mobile-3 {
		padding: 1rem !important;
	}
	.pt-mobile-3 {
		padding-top: 1rem !important;
	}
	.pr-mobile-3 {
		padding-right: 1rem !important;
	}
	.pb-mobile-3 {
		padding-bottom: 1rem !important;
	}
	.pl-mobile-3 {
		padding-left: 1rem !important;
	}
	.p-mobile-4 {
		padding: 1.5rem !important;
	}
	.pt-mobile-4 {
		padding-top: 1.5rem !important;
	}
	.pr-mobile-4 {
		padding-right: 1.5rem !important;
	}
	.pb-mobile-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-mobile-4 {
		padding-left: 1.5rem !important;
	}
	.p-mobile-5 {
		padding: 3rem !important;
	}
	.pt-mobile-5 {
		padding-top: 3rem !important;
	}
	.pr-mobile-5 {
		padding-right: 3rem !important;
	}
	.pb-mobile-5 {
		padding-bottom: 3rem !important;
	}
	.pl-mobile-5 {
		padding-left: 3rem !important;
	}
	.m-mobile-auto {
		margin: auto !important;
	}
	.mt-mobile-auto {
		margin-top: auto !important;
	}
	.mr-mobile-auto {
		margin-right: auto !important;
	}
	.mb-mobile-auto {
		margin-bottom: auto !important;
	}
	.ml-mobile-auto {
		margin-left: auto !important;
	}
	.mat-checkbox-label {
		font-size: 14px !important;
		line-height: 17px !important;
    	margin-left: 10px;
	}
	.fs-11 {
		font-size: 13px !important;
	}
	footer.fs-16, .fs-18{
		font-size: 15px !important;
		line-height: 22px !important;
	}
	p.fs-20, div.fs-20, span.fs-20 {
		font-size: 16px !important;
		line-height: 22px;
	}
	.fs-30 {
		font-size: 27px !important;
	}
	.fs-36 {
		font-size: 24px !important;
	}

	.lh-2 {
		line-height: normal;
	}
	.btn.btn-act.btn-xxl {
		font-size: 16px;
		height: 50px;
		.icon-expand:before {
			font-size: 12px;
		}
	}
}