
.m-1per {
	margin: 1%;
}

// first header cell of the tables with a photo
.pl-60px {
	padding-left: 60px;
}


.f-1 {
  flex: 1;
}
.f-2 {
	flex: 2;
}
.f-100 {
	flex: 100%;
}

.max-w_35per {
	max-width: 35% !important;
}

.max-w_38_5per {
	max-width: 38.5%;
}
.max-w_160px {
	max-width: 160px;
}
.max-w_200px {
	max-width: 200px !important;
}
.max-w_300px {
	max-width: 300px;
}
.max-w_500px {
	max-width: 500px;
}
.max-w_960px {
	max-width: 960px;
}

.mw-100 {
	max-width: 100% !important;
}


.min-w-unset {
	min-width: unset !important;
}

.min-h-unset {
	min-height: unset !important;
}

.max-h-auto {
	max-height: unset !important;
}

.min-h-95px {
	min-height: 95px !important;
}

.min-h-45px {
	min-height: 45px !important;
}

.min-h-110px {
	min-height: 110px !important;
}

.min-h-190px {
	min-height: 190px !important;
}

.min-h-200px {
	min-height: 200px !important;
}

.min-h-300px {
	min-height: 300px !important;
}
.min-h-600px {
	min-height: 600px !important;
}

.max-h-60vh {
	max-height: 60vh !important;
}

.max-h-60px {
  max-height: 60px !important;
}

.min-h-100per {
	min-height: 100%;
}

.mw-125px {
  min-width: 125px;
}

.mw-180px {
  min-width: 180px;
}
.min-w-250px {
  min-width: 250px;
}
.min-w-320px {
  min-width: 320px;
}
.min-w-25per {
	min-width: 25%;
}

.w8x8 {
	width: 8px !important;
	height: 8px !important;
}

.w10x10 {
	width: 10px !important;
	height: 10px !important;
}
.w12x12 {
	width: 12px !important;
	height: 12px !important;
}
.w14x14 {
	width: 14px !important;
	height: 14px !important;
}
.w15x15 {
  width: 15px;
  height: 15px;
}
.w16x16 {
	width: 16px !important;
	height: 16px !important;
}
.w18x18 {
	width: 18px !important;
	height: 18px !important;
}
.w20x20 {
	width: 20px !important;
	height: 20px !important;
}
.w24x24 {
	width: 24px !important;
	height: 24px !important;
}
.w30x30 {
	width: 30px !important;
	height: 30px !important;
	min-width: 30px;
}
.w36x36 {
	width: 36px !important;
	height: 36px !important;
	min-width: 36px;
}

.w40x40 {
	width: 40px !important;
	height: 40px !important;
	min-width: 40px;
}
.w45x45 {
	width: 45px !important;
	height: 45px !important;
	min-width: 45px;
}
.w50x50 {
	width: 50px !important;
	height: 50px !important;
	min-width: 50px;
}
.w60x60 {
	width: 60px !important;
	height: 60px !important;
	min-width: 60px;
}
.w80x80 {
	width: 80px !important;
	height: 80px !important;
	min-width: 80px;
}
.w90x90 {
	width: 90px !important;
	height: 90px !important;
	min-width: 90px;
}

.w-20px {
	width: 20px;
}

.w-30px {
	width: 30px;
}
.w-40px {
	width: 40px;
}
.w-50px {
	width: 50px !important;
}
.w-60px {
	width: 60px !important;
}

.w-80px {
	width: 80px !important;
}

.w-100px {
	width: 100px !important;
}
.w-120px {
  width: 120px !important;
}
.w-130px {
	width: 130px !important;
}
.w-150px {
	width: 150px !important;
}
.w-160px {
	width: 160px !important;
}

.w-185px {
	width: 185px !important;
}
.w-200px {
	width: 200px !important;
	flex: 0 0 200px;
	min-width: 200px;
	max-width: 200px;
}
.w-220px {
	width: 220px;
	flex: 0 0 220px;
	min-width: 220px;
	max-width: 220px;
}
.w-250px {
	width: 250px;
	flex: 0 0 250px;
	min-width: 250px;
	max-width: 250px;
}
.w-290px {
	width: 290px;
	flex: 0 0 290px;
	min-width: 290px;
	max-width: 290px;
}
.w-300px {
	width: 300px !important;
	min-width: 300px;
	max-width: 300px;
}
.w-335px {
	width: 335px !important;
	min-width: 335px;
	max-width: 335px;
}
.w-344px {
	width: 344px !important;
}
.w-350px {
	width: 350px !important;
}
.w-370px {
	width: 370px !important;
	flex: 0 0 370px;
}
.w-408px {
	width: 408px !important;
	flex: 0 0 408px;
}
.w-500px {
	width: 500px !important;
	flex: 0 0 500px;
}
.w-550px{
  width: 550px !important;
}
.w-600px {
	width: 600px !important;
}
.w-655px {
	width: 655px !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
	width: 20% !important;
}
.w-25 {
	width: 25% !important;
}
.w-30 {
	width: 30% !important;
}
.w-33 {
	width: 33% !important;
}
.w-35 {
	width: 35% !important;
}
.w-40 {
	width: 40% !important;
}
.w-45 {
	width: 45% !important;
}

.w-48-5 {
  width: 48.5% !important;
}

.w-49 {
  width: 49.0% !important;
}

.w-50 {
	width: 50% !important;
}

.w-60 {
	width: 60% !important;
}

.w-65 {
	width: calc(100% - 33%);
	flex: 0 0 calc(100% - 33%);
}

.w-75 {
	width: 75% !important;
}

.w-80 {
	width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 90% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.min_w-100px {
	min-width:100px;
	flex: 0 0 100px;
}

.min_w-25 {
	min-width:25%;
}

.min_w-40 {
  min-width:40%;
}

.min_w-50 {
	min-width:50%;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}
.h-2px {
	height: 2px !important;
}
.h-4px {
	height: 4px !important;
}

.h-20px {
	height: 20px !important;
}
.h-30px {
	height: 30px !important;
}
.h-38px {
	height: 38px !important;
}
.h-40px {
	height: 40px !important;
}

.h-50px {
	height: 50px !important;
	min-height: 50px;
}

.h-60px {
	height:60px;
}

.h-75px {
	height: 75px !important;
}
.h-100px {
	height: 100px !important;
}
.h-120px {
	height: 120px !important;
}
.h-143px {
	height: 143px !important;
}
.h-160px {
	height: 160px !important;
}
.h-170px {
	height: 170px !important;
}
.h-250px {
    height: 250px;
}
.h-400px {
	height: 400px;
}
.h-472px {
	height: 472px;
}
.h-504px {
	height: 504px;
}
.h-600px {
	height: 600px;
}
.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}
.h-100vh {
	height: 100vh;
}

.h-auto {
	height: auto !important;
}

.max-h-250px {
	max-height: 250px !important;
}

.max-h-50vh {
	max-height: 50vh !important;
}

.mh-100 {
	max-height: 100% !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.m-6 {
	margin: 5rem !important;
}

.mt-6,
.my-6 {
	margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
	margin-right: 5rem !important;
}

.mb-6,
.my-6 {
	margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
	margin-left: 5rem !important;
}

.m-7 {
	margin: 7rem !important;
}

.mt-7,
.my-7 {
	margin-top: 7rem !important;
}

.mr-7,
.mx-7 {
	margin-right: 7rem !important;
}

.mb-7,
.my-7 {
	margin-bottom: 7rem !important;
}

.ml-7,
.mx-7 {
	margin-left: 7rem !important;
}

.p-0 {
	padding: 0 !important;
}

.p-10px {
  padding: 10px;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.pl-4-5 {
  padding-left: 1.8rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

.top-auto {
	top:auto !important;
}

.right-0 {
	right: 0 !important;
}
.left-0 {
	left: 0 !important;
}
.top-0 {
	top: 0 !important;
}
.top-minus10 {
	top: -10px;
}
.top-minus25 {
	top: -25px;
}
.right-1 {
	right: 1rem !important;
}
.bottom-0 {
	bottom: 0 !important;
}

@media all and (min-width: 1024px) {
  .profile-wrapper {
    width: 900px;
  }
  /*.p-care-team_wrapper {
  	width: 775px;
  }*/
  .qr-wrapper {
  	width: 400px;
  }
}
