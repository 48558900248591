.row-3 {
  margin: 0 -1rem;
}

.hide {
  display: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.o-x-hidden {
  overflow-x: hidden !important;
}

.opacity-0 {
  opacity: 0;
}
.half-opacity {
  opacity: 0.5;
}
.vert-text {
  transform: rotate(-90deg);
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.float-right {
  float: right;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-content-center,
.center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-evenly {
  -ms-flex-pack: justify;
  justify-content: space-evenly;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.align-items-start {
  align-items: start;
}
.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-self-center {
  align-self: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.z-up-1 {
  z-index: 2;
}
.z-0 {
  z-index: 0;
}

.c-d {
  cursor: default !important;
}
.c-p {
  cursor: pointer;
  transition: opacity 0.15s linear;
  &:hover {
    opacity: 0.65;
  }
}
.act-circle {
  border: 1px solid $main_normal_blue;
  box-sizing: border-box;
}
.border-none, .border-0 {
  border: 0 !important;
}
.brdr-box {
  box-sizing: border-box;
}
.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(188, 199, 218, 0.4);
}
.o-auto {
  overflow: auto !important;
}
.o-hidden {
  overflow: initial;
}
.ox-hidden {
  overflow-x: hidden;
}

// text manipulation

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap,
.nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  white-space: normal;
}

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center,
.center_text {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-first-up::first-letter {
  text-transform: capitalize;
}

.white {
  color: #fff;
  &:before {
    color: #fff;
  }
}
.border-dashed {
  border: 2px dashed #87B8D8;
}
.border-red {
  border: 1px solid $main_normal_red;
}
.border-radius-4px {
  border-radius: 4px !important;
}
.border-radius-20px {
  border-radius: 20px !important;
}
.border-radius-0-4px {
  border-radius: 0 4px 4px 0 !important;
}
.border-radius-0-0-4px {
  border-radius: 0 0 4px 4px !important;
}
.border-radius-4px-4px-0 {
  border-radius: 4px 4px 0 0 !important;
}
.border-top-none {
  border-top: 0 !important;
}

.border-bottom-none {
  border-bottom: 0 !important;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.border-bottom {
  border-bottom: 1px solid $main_gray_06;
}
.border-bottom-gray {
  border-bottom: 1px solid $main_gray_08;
}
.border-bottom-blue {
  border-bottom: 1px solid $main_normal_blue;
}

.border-top {
  border-top: 1px solid $main_gray_06;
}
.border-top-wide {
  border-top: 5px solid $main_gray_08;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right {
  border-right: 1px solid $main_gray_06;
}

.border-left {
  border-left: 1px solid $main_gray_06;
}
.border-1 {
  border: 1px solid $main_gray_06;
}
.bordered .border-left {
  border-left: 0;
}
.border-radius-10-100 {
  border-radius: 20% 100% 100% 100% !important;
}
.border-radius-100 {
  border-radius: 100% !important;
}
.border-radius-2px {
  border-radius: 2px !important;
}
.brd-r-5px {
  border-radius: 5px !important;
}
.border-radius-10px {
  border-radius: 10px !important;
}
.border-radius-30px {
  border-radius: 30px !important;
}
.border-radius-80px {
  border-radius: 80px !important;
}
.border-blue {
  border-color: $main_normal_blue !important;
}
.border-light-blue {
  border-color: $main_light_blue !important;
}
.border-green {
  border-color: $main_normal_green !important;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.zoom-07 {
  zoom: 0.7;
}
