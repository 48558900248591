/* You can add global styles to this file, and also import other style files */
@import '../../node_modules/angular-calendar/css/angular-calendar.css';
//@import "../../node_modules/highcharts/css/highcharts.css";
@import 'cp-theme';
@import 'font-family';
@import 'font-size';
@import 'fast-sizes';
@import 'perform';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'icons';
@import 'mat-overwrite';
@import 'ng5slider-overwrite';
@import 'datatable';
@import 'media-tablet';
@import 'ie';
@import '~@angular/cdk/overlay-prebuilt.css';
@import "common-style.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";


@media print {
  .no_print {
    display: none !important;
  }

  .yes_print {
    display: block !important;
  }
}

// has to be implemented globally.
* {
  box-sizing:border-box;
}
.root {
  margin: 0;
  overflow: hidden;
  //font-size: calc(100vw / 100 + 3px);
  //font-family: 'Nunito Sans', serif;
  font-family: 'Roboto', sans-serif !important;
  color: $heading-text;
}

html,
body,
input,
button,
a,
textarea,
.font-family {
  //font-family: 'Roboto', sans-serif !important;
  font-family: 'Roboto', sans-serif !important;
}

.right_content,
.patient-not-login {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow-x: auto;
  background-color: $main_gray_01;
  /* app-title-bar ~ .main_content app-organizations app-org-overview .org-overview {
    height: calc(100vh - 230px - 2rem) !important;
  } */
}

.dashboard-height {
  height: calc(100vh - 120px);
}

.mat-drawer-content {
  overflow: unset !important;
}

.main_content {
  margin: 0;
  background: #f3f6f9;
  flex: 1;
  overflow: auto;

}
// @media(min-width:768px){
//   .main_content {
//     overflow: auto;
//   }
// }

.spacer {
  flex-grow: 1;
}

button,
input {
  outline: none;
}

body.root *::-moz-placeholder {
  line-height: revert !important;
  font-size: initial !important;
}

/* .cdk-overlay-pane {
  margin: 0 auto !important;
  justify-content: center !important;
} */

/* Calendar style */
.cal-month-view {
  color: $main_gray_07;
}

.cal-month-view .cal-days {
  background: white;
  border: 1px solid $main_gray_03;
  border-bottom: 0;
}

.cal-month-view .cal-day-number {
  @extend .font_size_md;
  opacity: 1;
  color: $main_gray_09;
  float: left;
  margin-left: 20px;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: $main_normal_red;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.4;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: $main_gray_01;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid $main_gray_03;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid $main_gray_03;
}

.cal-month-view .cal-header.cal-cell-row:hover {
  background-color: initial;
}

.cal-month-view .cal-header.cal-cell-row .cal-cell:hover {
  background-color: initial;
}

/* Calendar input styling */
.date_picker_container {
  position: relative;
  .date_picker_img {
    position: absolute;
    left: calc(70% - 15px);
    top: 45%;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

/* Modal styling */

.backdrop {
  position: fixed;
  background-color: rgba(140, 152, 160, 0.6);
  z-index: 99;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.backdrop::-webkit-scrollbar {
  display: none;
  width: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

div,
span,
p,
button {
  &.link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;

      .mat-button-focus-overlay {
        display: none;
      }
    }
  }
}

.btn-common {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid $main_gray_05;
  font-weight: 500;
  padding: 3px 26px;
  //letter-spacing: -0.5px;
  color: $main_gray_07;
  @include responsive-font(16, 23);
  line-height: 2em;
  max-height: 45px;
  white-space: nowrap;
}

.btn-xs {
  border-radius: 80px !important;
  background-color: #ffffff;
  border: 1px solid $main_gray_03;
  font-weight: 500;
  padding: 0 27px;
  color: $primary-color;
  @include responsive-font(12, 16);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
  &.light-blue {
    border: 1px solid $light-blue;
  }
}

.btn-sm {
  height: 40px;
  color: $main_lighter_blue;
  border: 1px solid $main_lighter_blue;
  border-radius: 80px;
  padding: 8px 25px;
  min-width: 185px;
  background-color: #fff;
}

.btn-xxs {
  height: 30px;
  color: $main_lighter_blue;
  border: 1px solid $main_lighter_blue;
  border-radius: 80px;
  display: flex;
  font-size: 12px;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.btn-xl {
  font-size: 16px;
  height: 44px !important;
  padding: 0 2rem !important;
  border-radius: 80px;
}

.btn-xxl {
  font-size: 20px;
  height: 62px;
  padding: 0 2rem !important;
  border-radius: 80px !important;
}
.btn-md{
  font-size: 14px;
  height: 36px !important;
  padding: 0 2rem !important;
  border-radius: 80px;
}
.btn-xsm {
  font-size: 12px;
  height: 29px !important;
  padding: 0 1rem !important;
  border-radius: 80px;
}
.btn-squared {
  border-radius: 4px;
  font-weight: 500;
  padding: 0 26px;
  //letter-spacing: -0.5px;
  color: $main_gray_07;
  @include responsive-font(16, 23);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

*[class*='btn-'],
*[class*='btn'] {
  letter-spacing: normal;

  &:not(.inactive).btn-act {
    outline: none;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.05s linear;
    border-top-right-radius: 80px;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    border-bottom-left-radius: 80px;

    &:hover {
      background-color: $primary-color;
      color: #ffffff;
      //border-color: $main_gray_05;
      // *[class*='icon'] {
      //   color: #ffffff !important;
      // }
    }

    &:active {
      background-color: $main_gray_07;
      border-color: $main_gray_07;
      color: #fff;
    }

    &.green-btn {
      border: 1px solid $main_light_green !important;
      color: $main_light_green;

      &:hover {
        background-color: $main_gray_02;
        border-color: $main_light_green;
      }

      &:active {
        background-color: $main_light_green;
        border-color: $main_light_green;
        color: #fff;
      }
    }
    &.yellow-btn {
      border: 1px solid $g-1 !important;
      color: $g-1;

      &:hover {
        background-color: $main_gray_02;
        border-color: $g-1;
      }

      &:active {
        background-color: $g-1;
        border-color: $g-1;
        color: #fff;
      }
    }

    &.blue-btn {
      border: 1px solid $primary-color !important;
      color: $primary-color;

      &:hover {
        background-color: $main_gray_02;
        border-color: $primary-color;
      }

      &:active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
      }
    }
    &.gray-btn {
      border: 1px solid $main_gray_08 !important;
      color: $main_gray_08;

      &:hover {
        background-color: $main_gray_08;
        border-color: $main_gray_08;
        color: #fff;
      }

      &:active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
      }
    }

    &.red-btn {
      color: $main_normal_red;
      border-color: $main_normal_red !important;
      background-color: transparent;

      &:hover,
      &:active {
        background-color: $main_normal_red;
        border-color: $main_normal_red;
        color: #fff;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &[class*='bg'] {
    border-color: transparent;
    &:not(.bg-white) {
      color: #fff;
    }
    &:hover {
      color: $main_lighter_blue;
    }
  }

  &.inactive {
    //pointer-events: none;
    opacity: 0.25;
    background-color: $main_gray_08;
    color: #fff !important;
  }

  .mat-button-focus-overlay {
    display: none;
  }
}
.btn.btn-activated.active {
  color: #fff !important;
  border: 0 !important;
  i {
    filter: invert(1);
    opacity: 1;
  }
}

img[class*='sort_order'] {
  margin: 0 5px;
}

.common-rounded_wrap-img {
  border-radius: 60px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
}

.div-act {
  outline: none;
  cursor: pointer;
  transition: all 0.05s linear;

  .hint-line {
    display: none;
    position: absolute;
    right: 0;
  }

  &:hover {
    background-color: $main_gray_02;

    .hint-line {
      display: inline-block;
    }
  }
}

.box-dsh {
  padding: 1rem;
  background: #fff;
  box-shadow: 0 2px 6px #e6e6e6;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Select Box */
.global_select_container {
  position: relative;
  margin: 5px 5px 5px 0;
  min-width: 100px;
  width: calc(100% / 7);
}

.global_select_container select {
  background-color: transparent;
  color: $main_gray_07;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 35px 8px 8px;
  font-weight: 500;
  letter-spacing: -0.5px;
  cursor: pointer;
  box-shadow: inset 0 1px 5px 0 rgba(167, 179, 187, 0.5);
  /* width: 86%; */
}

.global_select_container .arrow_down {
  width: 8px;
  height: 12px;
  position: absolute;
  right: 25px;
  top: calc(50% - 6px);
  z-index: 10;
}

.add_subtask_button {
  width: 84px;
  height: 32px;
  object-fit: contain;
  border-radius: 16px;
  border: solid 1px $main_lighter_blue;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: $main_lighter_blue;
  background-color: inherit;
  cursor: pointer;
}

/* checkbox */
.big_checkbox {
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #a7b3bb;
  padding: 10px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none;
  margin-right: 20px;
  cursor: pointer;
}

.big_checkbox:checked {
  background-color: $primary-color;
  border: 1px solid $primary-color;
}

.big_checkbox:checked:after {
  position: absolute;
  content: '\e90c';
  font-family: 'icomoon', serif !important;
  speak: none;
  font-size: 12px;
  color: #fff;
}

/*starts custome radio buttons styles*/
.radio-section,
.radio_section {
  ul.unstyled-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;

    li {
      float: left;
    }
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 15px;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .txt-color { color: $secondary-detail;}

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid $border-color;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 50%;
    margin-top: -6px;
    left: 50%;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $primary-color;
  }
}



/*ends custom radio buttons styles*/

/*starts custom switch buttons styles*/
.switch-button-section {
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;

    input {
      display: none;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $main_gray_04;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 0;
    bottom: -2px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: $b-shadow;
  }

  input:checked + .slider {
    background-color: $main_normal_green;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $main_normal_green;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

/*Under Construction Page Styling*/
.construction_parent_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.construction_parent_container div.img_container {
  width: 100%;
  margin-top: 10px;
  margin-left: -3%;
  text-align: center;

  img {
    width: 90%;
    max-width: 400px;
  }
}

.app-under-construction {
  display: flex;
}

/*ends custom switch buttons styles*/

/* Start: Prompt Modal Dialog styling */
.prompt-dialog-container {
  button {
    border: none !important;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
  }

  .deny_btn {
    color: $main_gray_07;
  }

  .mat-dialog-actions {
    justify-content: flex-end;
  }

  .mat-dialog-title {
    color: $primary-color !important;
    font-family: 'Nunito Sans', sans-serif !important;
    margin: 0 0 5px !important;
  }

  .mat-raised-button.mat-primary {
    background-color: $primary-color;
  }
}

/* End: Prompt Modal Dialog styling */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.right-content-area {
  min-height: calc(100vh - 190px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1456px) {
  .right-content-area {
    margin-left: 0 !important;
  }
  .form-group {
    padding: 0 48px !important;
  }
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 767px) {
  .main_content {
    height: calc(100% - 190px);
  }
  .custom-stepper {
    display: none;
  }
  .right-content-area {
    min-height: auto;
    .text-center {
      .right-container {
        h3 {
          font-size: 18px !important;
          font-weight: 500 !important;
          line-height: 28px !important;
        }
        p {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
    .patient-status {
      .patient-card {
        .mat-card-header {
          padding: 20px 10px !important;
          .mat-card-title {
            font-size: 16px !important;
          }
        }
        .mat-qr-card {
          flex-direction: column;
          .qr-content {
            width: 100% !important;
            order: 1;
          }
          .qr-code {
            width: 100% !important;
            margin-bottom: 12px;
            margin-left: 0 !important;
          }
        }
      }
    }
    .patient-next-appointment {
      .patient-two-cloumn {
        display: block !important;
        .card {
          width: 100% !important;
        }
      }
    }
    .btn-row {
      flex-direction: column;
      button {
        margin-bottom: 12px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .list-queue {
      ul {
        padding-left: 0;
        li {
          font-size: 14px;
          .material-icons {
            width: 18px !important;
            height: 18px !important;
            line-height: 18px !important;
            margin-right: 5px !important;
          }
        }
      }
    }
    .end-process {
      .card-title {
        font-size: 16px;
      }
      .card-mile {
        font-size: 13px !important;
      }
    }
  }
  .profile-container-row {
    justify-content: center !important;
    .profile-container-column {
      flex-direction: column !important;
      flex: 1;
      .img-container {
        margin-top: 45px !important;
        margin-bottom: 0 !important;
        display: block;
        .btn-edit {
          min-width: 270px;
          border-top-right-radius: 80px;
          border-top-left-radius: 80px;
          border-bottom-right-radius: 80px;
          border-bottom-left-radius: 80px;
        }
      }
    }
    .form-group {
      padding: 0 24px !important;
    }
  }
  .form-row{
    .form-control{
      .mat-select-trigger{
        height: 56px !important;
      }
    }
  }
  .checkbox-column{
    .example-checkbox{
      margin-right: 0 !important;
    }
  }
  .change-pswd {
    .mat-hint{
      .mat-icon{
        font-size: 16px !important;
      }
    }
  }
}

@media only screen and (max-width: 567px) {
  .right-content-area {
    .end-process {
      flex-direction: column;
      .col-md-3 {
        border-bottom: solid 1px #1072b2;
        border-right: none !important;
        width: 100% !important;
      }
      .col-md-4 {
        width: 100% !important;
      }
    }
  }
  .modal_title_container {
    .icon-close {
      &::before,
      &::after {
        height: 25px !important;
      }
    }
  }
  .form-group,
  .row-insurance {
    display: block;
    .form-6-col {
      width: 100% !important;
    }
  }
  .form-button {
    .mat-checkbox-label {
      white-space: normal;
    }
  }
  .btn-row{
    .btn-mini-fab{
      width: 32px;
      height: 32px;
      .mat-button-wrapper {
        padding: 0 !important;
        line-height: 16px !important;
      }
    }
  }
}

@media (min-width: 960px) {
  .large-dialog {
    .mat-dialog-container {
      width: 1060px;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .main_content {
    height: calc(100% - 193px);
  }
}

@media only screen and (min-width: 2600px) {
  .main_content {
    height: calc(100% - 223px);
  }
}

loading-spinner {
  width: 100%;
}

.modal_title_container {
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: space-between;
  //border-bottom: 1px solid $main_gray_04;
  min-height: 50px;
  align-items: center;

  .icon-close {
    margin: auto 20px auto 0;
  }

  & > .pop_options {
    border-bottom: 0;
  }
  .pop_options {
    display: flex;
    flex-flow: row wrap;
    width: 95%;
    margin-top: 10px;
  }
}

.title-line {
  height: 60px;
  border-bottom: 1px solid $main_gray_06;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-left: 20px;
  font-weight: 500;
  //letter-spacing: -0.5px;
  color: $main_gray_09;
  background-color: #fff;
}

.box-shadowed {
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: $b-shadow;
  box-sizing: border-box;
}

.stage-tag {
  border-radius: 3px;
  font-size: 11px;
  padding: 2px 8px;
  vertical-align: center;
  display: inline-block;
  color: #fff;
  background-color: $primary-color;
  margin-right: 10px;
}

.status_box,
.status-box {
  border-radius: 3px;
  border-left-width: 2px;
  box-sizing: border-box;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02rem;
  align-items: center;

  &:before {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 12px;
    content: '';
    margin-right: 8px;
    background-color: $main_gray_07;
  }

  &.active:before {
    background-color: $main_light_green;
  }
}

.status_good,
.status-good {
  &:before {
    background-color: $main_light_green;
  }
}

.status_middle,
.status-middle {
  &:before {
    background-color: $g-1;
  }
}

.status_bad,
.status-bad {
  &:before {
    background-color: $main_normal_red;
  }
}

.status_none,
.status-none {
  &:before {
    background-color: $main_lighter_blue;
  }
}

em.normal {
  font-style: normal;
}

.btn_no_border {
  border: none;
  background: none;
}

.back_to_provider_overview {
  height: 50px;
  flex: 0 0 50px;
  background-color: $main_gray_03;
  border-bottom: 1px solid $main_gray_06;
  font-size: 14px;
}

button {
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error_message,
.alert-danger,
.login_error {
  color: $main_normal_red;
  text-align: left;
  font-weight: bold;
  margin: 0 5px 10px 0;
}

.map-error-message {
  color: $main_normal_red;
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
}

.dragged_item_card {
  display: flex;
  flex-flow: row nowrap;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  position: relative;

  .handle {
    width: 20px;
    height: 20px;
    background: url('../assets/main/temp/list-question-card-mobility-icon.svg') no-repeat center;
    color: transparent;
    cursor: pointer;
  }

  .action_btns {
    opacity: 0;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .selected_flag {
    position: absolute;
    height: 25px;
    width: 25px;
    top: 0;
    left: 0;
    display: none;
  }
}

.choice_container.gu-mirror {
  background-color: $main_gray_02;
}

.titlebar_container {
  position: relative;

  .icon-nav-back {
    margin-right: 40px;
    position: relative;

    &:after {
      position: absolute;
      right: -21px;
      height: 20px;
      background-color: $main_gray_03;
      width: 1px;
      content: '';
    }

    &:hover ~ .title-hover {
      display: block;
      position: absolute;
      background-color: #fff;
      border-radius: 3px;
      padding: 2px 10px;
      box-shadow: $b-shadow;
      opacity: 1;
      font-weight: normal;
      font-size: 12px;
      color: $main_gray_09;
      top: -3px;
      left: 42px;
    }
  }

  .title-hover {
    display: none;
    opacity: 0;
  }
}

.dragged_item_card:hover {
  background-color: $main_gray_02;

  .action_btns {
    opacity: 1;
  }
}

.dragged_item_card.active {
  border: 2px solid $primary-color;

  .selected_flag {
    display: initial;
  }
}

.circle {
  background-color: $main_gray_03;
  border-radius: 15px;
  min-width: 30px;
  padding: 5px 0.5rem;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: -0.08rem;
}

.b-shadow {
  box-shadow: $b-shadow;
}

.table_top_container_filter {
  height: 65px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: $main_gray_01;
  color: $main_lighter_blue;

  ::ng-deep app-filter-bar {
    width: 70%;
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

div[class*='_list_header_label'] {
  cursor: pointer;
}

.scrollable {
  div[class*='_list_header_label']:last-child {
    margin-right: 17px;
  }
}

.missing-provider-label-container {
  text-align: center;
}

hr {
  border: 1px solid $main_gray_06;
  margin: 0;
  padding: 0;
}

hr.one-px {
  border-bottom: 0;
}

/* base colors classes */

.text_dark {
  color: $text_dark;
}

.main_normal {
  color: $primary-color;
}

.main_normal_red,
.red {
  color: $main_normal_red;
  &:before {
    color: $main_normal_red !important;
  }
}

.mat-snack-bar-container.snackbar-warning{
  background-color: #fff3cd !important;
}

.bg-testing {
  background-size: cover;
  background: url('../assets/main/bg-wal.svg') no-repeat center;
  background-color: $primary-color;
  padding-left: 0 !important;
  padding-right: 0 !important;
  h1 {
    width: auto;
    text-align: center;
    img {
      max-width: 305px;
    }
  }
}

.bg_pink {
  background: #ffebeb;
}
.bg_salad {
  background: #e8fff2;
}
.div-head-cell {
  color: rgba(0, 0, 0, 0.54);
}
.main_gray_10 {
  color: $main_gray_10;

  &:before {
    color: $main_gray_10 !important;
  }
}

tr.bg_pink td {color: $main_normal_red !important;}

.main_gray_09 {
  color: $main_gray_09 !important;

  &:before {
    color: $main_gray_09 !important;
  }
}

.main_gray_08 {
  color: $main_gray_08;

  &:before {
    color: $main_gray_08 !important;
  }
}

.main_gray_06 {
  color: $main_gray_06;
}

.main_gray_05 {
  color: $main_gray_05;
}

.main_gray_03 {
  color: $main_gray_03;
}

.main_lighter_blue {
  color: $main_lighter_blue;

  &::placeholder {
    color: $main_lighter_blue;
  }
}

.main_light_blue {
  color: $main_light_blue;
}

.light-blue {
  color: $light-blue;
}
.aqua-marine {
  color: $aqua-marine;
}
.main_light_red {
  color: $main_light_red;
}

.main_normal_green {
  color: $main_normal_green;
}

.main_normal_blue {
  color: $primary-color;
}

.walgreens_red{
  color:$walgreens_red;
}

.main_gray_07 {
  color: $main_gray_07;
}

.main_lavender {
  color: $main_lavender;
}

.main_light_green,
.green {
  color: $main_light_green;
}

.g-1,
.orange {
  color: $g-1;
}

.g-2 {
  color: $g-2;
}

.g-3 {
  color: $g-3;
}

.g-4 {
  color: $g-4;
}

.g-5 {
  color: $g-5;
}

.g-6 {
  color: $g-6;
}

.g-7 {
  color: $g-7;
}

.g-8 {
  color: $g-8;
}

.g-9 {
  color: $g-9;
}

.g-10 {
  color: $g-10;
}

.g-11 {
  color: $g-11;
}

.g-12 {
  color: $g-12;
}

.g-13 {
  color: $g-13;
}

.g-14 {
  color: $g-14;
}

.g-15 {
  color: $g-15;
}

.g-16 {
  color: $g-16;
}

.g-17 {
  color: $g-17;
}

.g-18 {
  color: $g-18;
}

.g-19 {
  color: $g-19;
}

.bg_main_lighter_blue {
  background-color: #f1f9fe;
}

.bg_main_gray_00 {
  background-color: $main_gray_00;
}

.bg-light-gray {
  background-color: $bg-light-gray;
}

.bg-light-blue {
  background-color: $light-blue;
}
.bg-light-blue-2 {
  background-color: $light-blue-2;
}
.bg-aqua-marine {
  background-color: $aqua-marine;
}

.bg_main_normal_blue, .btn-activated.active {
  background-color: $primary-color;
  border-radius: 80px !important;
}

.bg_main_lavender {
  background-color: $main_lavender;
}

.bg_main_normal_green,
.bg-accepted {
  background-color: $main_normal_green;
}

.bg_main_light_green {
  background-color: $main_light_green;
}

.bg_secondary_light_green {
  background-color: $secondary_light_green;
}

.bg_transparent {
  background-color: transparent;
}

.warning {
  color: #f5a623;
}

.bg_warning {
  background-color: #f5a623;
}

.bg_error,
.bg-rejected {
  background-color: $main_normal_red;
}

.error-red {
  color: $main_normal_red;
}

.bg-white {
  background-color: #fff;
}
.bg-inactive {
  background-color: $inactive;
}
.bg_main_gray_03 {
  background-color: $main_gray_03;
}

.bg_main_gray_04 {
  background-color: $main_gray_04;
}

.bg_main_gray_05 {
  background-color: $main_gray_05;
}
.bg_main_gray_07 {
  background-color: $main_gray_07;
}
.bg-main_gray_08 {
  background-color: $main_gray_08;
}

.bg-main_gray_09 {
  background-color: $main_gray_09;
}

.bg-main_gray_01 {
  background-color: $main_gray_01;
}

.bg_main_gray_02 {
  background-color: $main_gray_02;
}

.bg_main_gray_06 {
  background-color: $main_gray_06;
}

.bg_main_gray_ada {
  background-color: #607380;
}

.bg_light-yellow {
  background-color: $light-yellow;
}

.bg_awaiting-results {
  background-color: #0091ff;
}

/* common Goals colors */

.bg-g-1,
.bg-pending,
.plans-wrapper > div:nth-child(1) .progress {
  background-color: $g-1;
}

.bg-g-2,
.plans-wrapper > div:nth-child(2) .progress {
  background-color: $g-2;
}

.bg-g-3,
.plans-wrapper > div:nth-child(3) .progress {
  background-color: $g-3;
}

.bg-g-4,
.plans-wrapper > div:nth-child(4) .progress {
  background-color: $g-4;
}

.bg-g-5,
.plans-wrapper > div:nth-child(5) .progress {
  background-color: $g-5;
}

.bg-g-6,
.plans-wrapper > div:nth-child(6) .progress {
  background-color: $g-6;
}

.bg-g-7,
.plans-wrapper > div:nth-child(7) .progress {
  background-color: $g-7;
}

.bg-g-8,
.plans-wrapper > div:nth-child(8) .progress {
  background-color: $g-8;
}

.bg-g-9,
.plans-wrapper > div:nth-child(9) .progress {
  background-color: $g-9;
}

.bg-g-10,
.plans-wrapper > div:nth-child(10) .progress {
  background-color: $g-10;
}

.bg-g-11,
.plans-wrapper > div:nth-child(11) .progress {
  background-color: $g-11;
}

.bg-g-12,
.plans-wrapper > div:nth-child(12) .progress {
  background-color: $g-12;
}

.bg-g-13,
.plans-wrapper > div:nth-child(13) .progress {
  background-color: $g-13;
}

.bg-g-14,
.plans-wrapper > div:nth-child(14) .progress {
  background-color: $primary-color;
}

.bg-g-15,
.plans-wrapper > div:nth-child(15) .progress {
  background-color: $g-15;
}

.bg-g-16,
.plans-wrapper > div:nth-child(16) .progress {
  background-color: $g-16;
}

.bg-g-17,
.plans-wrapper > div:nth-child(17) .progress {
  background-color: $g-17;
}

.bg-g-18,
.plans-wrapper > div:nth-child(18) .progress {
  background-color: $g-18;
}

.bg-g-19,
.plans-wrapper > div:nth-child(19) .progress {
  background-color: $g-19;
}

.critical {
  color: $critical;
}

.poor {
  color: $poor;
}

.neutral {
  color: $neutral;
}

.good {
  color: $good;
}

.excellent {
  color: $excellent;
}

.bg_green_gradient {
  //background: linear-gradient(325deg, rgb(12, 130, 63) 0%, rgb(20, 178, 88) 41%, rgb(0, 255, 110) 100%);
  background: $primary-color;
}
.bg-critical {
  background-color: $critical;
}

.bg-poor {
  background-color: $poor;
}

.bg-neutral {
  background-color: $neutral;
}

.bg-good {
  background-color: $good;
}

.bg-excellent {
  background-color: $excellent;
}

.mat-progress-bar.critical {
  .mat-progress-bar-fill:after {
    background-color: $critical;
  }
}

.mat-progress-bar.poor {
  .mat-progress-bar-fill:after {
    background-color: $poor;
  }
}

.mat-progress-bar.neutral {
  .mat-progress-bar-fill:after {
    background-color: $neutral;
  }
}

.mat-progress-bar.good {
  .mat-progress-bar-fill:after {
    background-color: $good;
  }
}

.mat-progress-bar.excellent {
  .mat-progress-bar-fill:after {
    background-color: $excellent;
  }
}

.control-arrow-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-self: center;
  border: 1px solid $main_gray_06;
  border-radius: 15px;
  height: 28px;
  margin-right: 3%;
}

.bordered {
  border: 1px solid $main_gray_06;
  border-radius: 4px;
  margin-bottom: 40px;
  position: relative;
  box-sizing: border-box;

  .border-left {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 5px;
      background-color: #ddd;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
  &.border-white {
    border-color: #fff;
  }
}

.bordered-2 {
  border: 2px solid #ebeef0 !important;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}

.cdk-overlay-container .bordered-2 *[class*='input'] {
  border-width: 0 !important;
}

.patients-list tr:not(.active-borders) {
  td {
    background-color: $main_gray_00 !important;
  }
}

.bordered:nth-child(1),
.plans-wrapper div:nth-child(1) .bordered,
.plans-wrapper > div:nth-child(1) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-1;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-1;
  }
}

.bordered:not(.no-default-border):nth-child(2),
.plans-wrapper div:nth-child(2) .bordered,
.plans-wrapper > div:nth-child(2) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-2;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-2;
  }
}

.bordered:nth-child(3),
.plans-wrapper div:nth-child(3) .bordered,
.plans-wrapper > div:nth-child(3) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-3;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-3;
  }
}

.bordered:nth-child(4),
.plans-wrapper div:nth-child(4) .bordered,
.plans-wrapper > div:nth-child(4) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-4;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-4;
  }
}

.bordered:nth-child(5),
.plans-wrapper div:nth-child(5) .bordered,
.plans-wrapper > div:nth-child(5) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-5;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-5;
  }
}

.bordered:nth-child(6),
.plans-wrapper div:nth-child(6) .bordered,
.plans-wrapper > div:nth-child(6) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-6;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-6;
  }
}

.bordered:nth-child(7),
.plans-wrapper div:nth-child(7) .bordered,
.plans-wrapper > div:nth-child(7) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-7;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-7;
  }
}

.bordered:nth-child(8),
.plans-wrapper div:nth-child(8) .bordered,
.plans-wrapper > div:nth-child(8) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-8;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-8;
  }
}

.bordered:nth-child(9),
.plans-wrapper div:nth-child(9) .bordered,
.plans-wrapper > div:nth-child(9) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-9;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-9;
  }
}

.bordered:nth-child(10),
.plans-wrapper div:nth-child(10) .bordered,
.plans-wrapper > div:nth-child(10) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-10;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-10;
  }
}

.bordered:nth-child(11),
.plans-wrapper div:nth-child(11) .bordered,
.plans-wrapper > div:nth-child(11) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-11;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-11;
  }
}

.bordered:nth-child(12),
.plans-wrapper div:nth-child(12) .bordered,
.plans-wrapper > div:nth-child(12) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-12;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-12;
  }
}

.bordered:nth-child(13),
.plans-wrapper div:nth-child(13) .bordered,
.plans-wrapper > div:nth-child(13) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-13;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-13;
  }
}

.bordered:nth-child(14),
.plans-wrapper div:nth-child(14) .bordered,
.plans-wrapper > div:nth-child(14) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-14;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-14;
  }
}

.bordered:nth-child(15),
.plans-wrapper div:nth-child(15) .bordered,
.plans-wrapper > div:nth-child(15) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-15;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-15;
  }
}

.bordered:nth-child(16),
.plans-wrapper div:nth-child(16) .bordered,
.plans-wrapper > div:nth-child(16) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-16;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-16;
  }
}

.bordered:nth-child(17),
.plans-wrapper div:nth-child(17) .bordered,
.plans-wrapper > div:nth-child(17) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-17;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-17;
  }
}

.bordered:nth-child(18),
.plans-wrapper div:nth-child(18) .bordered,
.plans-wrapper > div:nth-child(18) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-18;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-18;
  }
}

.bordered:nth-child(19),
.plans-wrapper div:nth-child(19) .bordered,
.plans-wrapper > div:nth-child(19) .tasks-list-wrapper {
  .goal-ttl,
  .img-wrap i[class*='icon-']:before,
  .icn_wrap i[class*='icon-']:before,
  .goal_progress_percent {
    color: $g-19;
  }

  .border-left:before,
  .goal_progress_fill,
  .current_goal {
    background-color: $g-19;
  }
}

.login_pass_eye_img {
  position: absolute;
  right: 30px;
  top: 37%;
  width: 1.5em;
  height: 1em;
  cursor: pointer;
}

img#tooltip {
  padding: 0 5px;
}

[hidden],
.hidden {
  display: none !important;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #181818 !important;
  opacity: 1!important;
  background-color: #f4f4f4 !important;
}

.footer,
.button_container,
.pagination_container {
  .next_btn,
  *[class*='next_btn'] {
    background-color: $primary-color;
    border-radius: 80px;
    border: 0;
    padding: 0 24px;
    cursor: pointer;
    justify-content: center;
    min-width: 100px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    height: 36px;
    font-size: 12px !important;
    color: #fff;

    &.inactive,
    &[class*='invalid'],
    &.mat-button-disabled {
      background-color: #f3f4f5 !important;
      color: $main_gray_09 !important;
      //cursor: default !important;

      &:active {
        //pointer-events: none;
      }
    }

    &:not(.inactive):active {
      box-shadow: unset;
      outline: none;
    }
  }

  .back_btn,
  .cancel_btn {
    border-radius: 80px;
    background-color: #ffffff;
    border: 1px solid $main_gray_03;
    font-weight: 500;
    padding: 0 27px;
    color: $primary-color;
    @include responsive-font(12, 16);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    white-space: nowrap;
    font-size: 12px !important;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    &:active {
      outline: none;
    }

    &:not(.btn) {
      margin-right: 15px;
    }
  }
}

.onboard_container {
  *[class*='master'] {
    .input_container {
      display: block !important;

      label {
        display: inline-block;
        width: 100%;
      }
    }
  }
}

.mat-checkbox-disabled, .mat-form-field-disabled, .custom-input-disabled {
  opacity: 0.4 !important;
}
.mat-form-field-subscript-wrapper {
  width: 85% !important;
}

app-missing-data-component {
  width: 100%;
}

ol.breadcrumb {
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: center;

  li {
    list-style: none;
    margin: 4px 4px 4px 6px;
    font-weight: 500;
    font-size: 13px;

    a {
      color: $main_lighter_blue;
      text-decoration: none;
      display: inline-block;

      &:after {
        content: '\e911';
        display: inline-block;
        vertical-align: inherit;
        margin: 0 0 0 0.5rem;
        color: $main_gray_03;
        font-size: 11px;
        font-family: 'icomoon', serif;
        transform: rotate(-180deg);
      }
    }

    span {
      color: $primary-color;
    }
  }
}

.title-container,
.pop_options {
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid $main_gray_06;
  padding-left: 1rem;
  font-weight: 500;

  ul {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .title,
  .title_box {
    color: $main_gray_07;
    letter-spacing: .5px;
    font-size: 14px;
    display: inline-flex;
    height: 50px;
    margin: 0 15px;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    &:not(.active):hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: $primary-color;
      position: relative;
      cursor: default;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $primary-color;
      }
    }
  }
}

.title-container-sm {
  height: 36px;
  min-height: 36px;
  font-weight: 400;

  .title {
    color: $main_gray_07;
    font-size: 13px;
    display: inline-flex;
    height: 36px;
    margin: 0 5px;
    padding: 0 5px;
    justify-content: center;
    align-items: center;

    &:not(.active):hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: $main_gray_09;
      position: relative;
      cursor: default;
      font-weight: 500;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $primary-color;
      }
    }
  }
}

.title_box,
.title {
  .wizard_number {
    border-radius: 100%;
    height: 32px;
    width: 32px;
    flex: 1 0 32px;
    max-width: 32px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: $main_gray_06;
    justify-content: center;
  }

  &.active {
    .wizard_number {
      background-color: $primary-color;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        z-index: 1;
      }
    }
  }
}

.tab_titles_container {
  .tab {
    color: $main_gray_07;
    padding: 2px 12px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background-color: $primary-color;
      border-radius: 4px;
      cursor: default;

      .circle-status {
        border: 2px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.circle-status {
  border-radius: 100%;
  width: 8px;
  min-width: 8px;
  height: 8px;
  margin-right: 5px;
  box-sizing: border-box;
  position: relative;
  &.active {
    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid #4a4a4a;
      position: absolute;
      left: -3px;
      top: -3px;
      border-radius: 100%;
    }
  }
}

.filter-search {
  border-top: 1px solid $main_gray_06;

  input {
    border: 0;
    box-sizing: border-box;
    //width: 100%;
    &::placeholder {
      color: $main_gray_07;
    }
  }
}

.input_container {
  *.input_label,
  .label,
  .label.font_size_sm {
    box-sizing: border-box;
    line-height: 14px !important;
    font-size: 12px !important;
    color: $main_gray_08 !important;
    margin-top: 18px !important;
    margin-bottom: 0.25rem !important;
    position: relative;
    display: inline-block;

    & + *[class*='input'],
    & + * {
      margin-bottom: 0 !important;
    }
    .mat-hint{
      display: block;
    font-size: 0.875em;
    margin-top: .25rem;
    }
  }
}

.input-common,
.mat-input-element {
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  outline: none;
  border: solid 1px $main_gray_08;
  margin: 5px 0 10px 0;

  &::placeholder {
    color: $main_gray_05;
  }

  &:focus {
    border: 2px solid $primary-color;
  }

  &.input_invalid {
    border: 1px solid $main_normal_red !important;
    &::placeholder {
      color: $main_normal_red !important;
    }
  }
}

.input_invalid {
    &::placeholder {
      color: $main_normal_red !important;
    }
  }

input[class*='_input'],
.input_container input[class*='-field']:not(.mat-form-field-autofill-control),
.input_container input[class*='_field'],
.send_email_container {
  box-shadow: unset !important;
  border: solid 1px $main_gray_08 !important;

  &.input_invalid {
    border: 1px solid $main_normal_red !important;
  }

  &:focus {
    border: 2px solid $primary-color !important;
  }
}

.textarea_section {
  box-shadow: unset !important;
  border: solid 1px $main_gray_08 !important;

  &[class*='invalid'] {
    border: 1px solid $main_normal_red !important;
  }

  &:focus {
    border: 2px solid $primary-color !important;
  }
}

textarea.border-none {
  outline: none;
}

:not(app-upload-icon, app-multi-select-dropdown, .unstyled-list, app-datepicker-sep, .mat-radio-group).input_invalid,
app-upload-icon.input_invalid .icon_upload_container,
image-library-loader.input_invalid,
app-dropdown.input_invalid {
  display: inline-block;

  *:not(.mat-select-arrow) {
    border: 0 !important;
  }
}

.mat-radio-group.input_invalid {
  .mat-radio-outer-circle {border-color: $main_normal_red !important;}
  .mat-radio-label-content {color: $main_normal_red !important;}
}

.masterdata_textfield.input_invalid,
.add_new_department_container input.input_invalid,
.goal_name input.input_invalid,
.input_container input.input_invalid,
app-upload-icon.input_invalid .icon_upload_container,
app-dropdown.input_invalid,
image-library-loader.input_invalid,
mat-select.input_invalid,
app-birth-day-calendar.input_invalid, app-datepicker-sep.input_invalid .mat-form-field-wrapper input {
  border: 1px solid $main_normal_red !important;
  border-radius: 4px;
  box-sizing: border-box;
}

app-specialties-chip.input_invalid .mat-chip-input::placeholder, app-datepicker-sep.input_invalid input::placeholder {
  color: $main_normal_red !important;
}

.unstyled-list.input_invalid {
  label {
    color: $main_normal_red;
  }

  .checkmark {
    border: 1px solid $main_normal_red !important;
  }
}

.flash_button {
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  //Firefox 1+
  -webkit-animation-name: flash;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

  //Safari 3-4
  -moz-animation-name: flash;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
}

@keyframes flash {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

//Firefox 1+
@-webkit-keyframes flash {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

//Safari 3-4
@-moz-keyframes flash {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

a {
  opacity: 1;
  color: $primary-color;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
    //text-decoration: underline;
  }
}

//activity drawer
.activity-right_container {
  position: fixed;
  width: 350px;
  right: -350px;
  box-sizing: border-box;
  bottom: 0;
  top: 118px;
  transition: all 0.3s linear;
  z-index: 2;
  overflow: hidden;
}

.activity-lbl {
  cursor: pointer;
  z-index: 2;
  position: fixed;
  top: 40vh;
  right: 0;
  background-color: $main_light_blue;
  background-image: url('../assets/main/patient/note-badge-icon.svg');
  background-repeat: no-repeat;
  background-position: 14px 13px;
  background-size: 16px;
  color: #fff;
  letter-spacing: 0.8px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 40px;
  opacity: 0.8;
  white-space: nowrap;
  border-radius: 4px 0 0 4px;
  transition: all 0.3s linear;

  i {
    opacity: 0;
    width: 0;
    display: none;
    transition: all 0.3s linear;
    transform: rotate(180deg);
    margin-top: -23px;
  }

  &:hover {
    opacity: 1;
    width: auto;

    i {
      width: auto;
      display: block;
      opacity: 1;
      margin-right: 10px;
    }
  }

  span {
    transform: rotate(-90deg);
  }
}

#activity-show:checked {
  & ~ .activity-right_container {
    right: 0;
    overflow: visible;
  }

  & + .activity-lbl {
    right: 350px;

    i {
      transform: rotate(0deg);
    }
  }
}

.chart-point {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 100%;
  position: relative;
  width: 14px;
  height: 14px;
  box-shadow: 0 0 5px #efefef;
  &:after {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 100%;
    content: '';
    display: block;
    border: 2px solid #fff;
  }
  & ~ div,
  & + div {
    line-height: 13px;
  }
}
.chart-line {
  display: flex;
  &:before,
  &:after {
    display: inline-block;
    content: '';
    width: 8px;
    height: 2px;
    background: #a7b3bb;
    border-radius: 10px;
  }
  &:before {
    margin-right: 2px;
  }
}
/*end*/

mat-dialog-container {
  & > * {
    min-width: 400px;
  }
  & > app-org-calendar-event-dialog {
    min-width: unset;
  }

  h1.mat-dialog-title.modal_title_container {
    padding-left: 20px;
  }
}

@media all and (max-width: 560px) {
  mat-dialog-container > * {
    min-width: unset;
  }
}

app-md-therapy-dialog,
app-md-specialty-dialog,
app-md-service-type-dialog,
app-assign-provider-dialog,
app-care-team-dialog,
app-family-member-dialog {
  & > div.ng-star-inserted {
    min-width: 760px;

    h1.mat-dialog-title.modal_title_container {
      margin-bottom: 0 !important;
    }

    .mat-dialog-content {
      padding: 0;
    }
  }
}

app-cp-task-details-dialog {
  & > div.ng-star-inserted {
    h1.mat-dialog-title.modal_title_container {
      padding-left: 20px;
    }
  }
}

app-assessment-onboard-dialog {
  & > div.ng-star-inserted {
    min-width: 550px;
  }
}

app-p-note-dialog,
app-cp-task-appointment-summary-dialog {
  display: block;
  width: 600px;
}
//app-org-onboard-dialog, app-assign-provider-dialog {
//  display: block;
//  width: 800px;
//}
app-org-onboard-dialog,
app-cp-task-details-dialog,
app-ae-patient-appointment,
app-patient-enroll-service,
app-ae-appointment,
app-ae-patient {
  display: block;
  width: 800px;
}
app-onboard-patients {
  display: block;
  width: 860px;
}
app-add-event-overview {
  display: block;
  width: 880px;
}
app-cp-onboard-dialog {
  display: block;
  min-width: 960px;
}
image-library-dialog {
  display: block;
  width: 960px;
}
.description_container {
  box-sizing: border-box;
}

app-version-check-dialog {
  display: block;
  width: 400px;
}

.highcharts-container {
  * {
    font-family: 'Roboto', sans-serif !important;
  }
}

.highcharts-root {
  font-family: 'Roboto', sans-serif !important;
}

::ng-deep .cdk-live-announcer-element *,
::ng-deep .cdk-live-announcer-element,
.cdk-live-announcer-element,
.cdk-live-announcer-element * {
  font-size: 14px !important;
}
.activity-divider {
  justify-content: stretch;
  &:before,
  &:after {
    flex: 1;
    content: '';
    display: inline-block;
    height: 1px;
    background-color: $main_gray_03;
  }
}
.icons-mat-rewrite {
  .mat-icon {
    &.expand,
    &.expanded {
      margin: -5px 0 0 0 !important;
      background-color: #d6e3eb;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      &:before {
        border-style: solid;
        border-color: #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        vertical-align: middle;
        content: '';
      }
    }
    &.expanded {
      background-color: $primary-color;
      transform: rotate(180deg);
    }
  }
}
.add-new-wrapper {
  border: 1px dashed #87b8d8;
  background: #fafdff;
  border-radius: 6px;
  box-sizing: border-box;
  .icon-plus {
    background: #e2eff7;
  }
  &.inactive {
    pointer-events: none;
    opacity: 0.25;
  }
}

*::placeholder {
  line-height: inherit !important;
}

.wrap-img img {
  min-height: 100%;
}
.gm-style .gm-style-iw {
  font-weight: inherit !important;
  color: #00171f;
}

.required-asterisk {
  color: red;
    font-weight: bold;
    margin-left: 3px;
    line-height: inherit !important;
    font-size: 12px;
    display: inline-block;
    height: 5px;
    padding: 0 !important;
}

.tab-no-border {
  .material-icons {
    font-family: 'Material Icons', serif !important;
  }
  .mat-tab-header {
    border-bottom: 0 !important;
    .mat-tab-label {
      padding: 0 10px !important;
    }
    .mat-tab-label-active {
      border-bottom: 3px solid $primary-color;
      font-weight: 600;
    }
  }
}

mat-checkbox.input_invalid {
  .mat-checkbox-layout .mat-checkbox-inner-container {
    border: 1px solid $main_normal_red !important;
  }
  .mat-checkbox-label {
    color: $main_normal_red !important;
  }
}

// Third tab scss start here
.patient-card-header {
  .mat-card-header-text {
    width: 100%;
    text-align: center;
  }
}
ngx-dropzone-label {
  margin: 0 auto !important;
}

.form-row{
  .form-control{
    .mat-select-trigger {
      height: 73px;
      padding: 0 10px;
      border-radius: 5px;
      outline: none;
      border: solid 1px #a8bece;
      width: 100%;
      background-color: transparent;
      box-sizing: border-box;
      font-size: 18px;
      color: #070707;
    }
  }
}
.organization_main_info, .health-vaccination-wrapper, .add-edit-patient-wrapper {
  app-geocode-address, app-doctor-information, app-insurance-information {
  .form-row .form-control .mat-select-trigger { height: 40px; font-size: inherit;}
  .form-row .form-control .mat-form-field-infix .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
    margin-top: 0 !important;}
  }
  app-insurance-information .app-insurance-information .mat-select-trigger, app-insurance-information .app-insurance-information .mat-input-element {
    font-size: inherit;
  }
  .app-doctor-information mat-expansion-panel-header {
    height: 27px;
    &.mat-expanded {
        margin-bottom: 20px;
        padding-bottom: 0.5rem !important;
    }
    &:not(.mat-expanded) {
      border-bottom: 0 !important;
    }
  }
  .app-insurance-information .mat-select-trigger, .app-insurance-information .mat-input-element {
    height: 40px !important;
  }
}
.inners-insurance-information .app-insurance-information {
  padding: 1.5rem !important;
  .fs-22.fw-500.pt-2.pb-2 {
    font-size: 16px !important;
    & ~ .main_gray_09.ft-16 {
      font-size: 14px !important;
    }
  }
  .mat-form-field-infix .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
    margin-bottom: -6px;
  }
}

.add-edit-patient-wrapper app-doctor-information {
  mat-label p {
    position: absolute;
    bottom: -20px;
    font-size: 11px !important;
    color: #8c98a0 !important;
  }
}
.gm-ui-hover-effect {
  width: 49px !important;
    height: 44px !important;
}
.gm-ui-hover-effect>span{
    width: 28px !important;
    height: 28px !important;
    margin: 0 !important;
    right: 18px;
    position: absolute;
    top: 14px;

}

.mat-menu-content{
  padding: 0 !important;
  button{
    border-bottom: 1px solid rgba(188, 199, 218, 0.4);
    width: 100%;
    text-align: left;
    border-radius: 0;
  }
  .btn-logout{
    color: #ee2e24;
    font-weight: 600;
  }
}
.width-16 {
  width: 24px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.custom-radios {
  margin-top: 15px;
  min-width: 325px !important;
  .list-column{
    padding: 8px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dadada;
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 30px;
    }
    .mat-list-item-content{
      display: block !important;
      padding-left: 0 !important;
      color: #121c22;
      font-size: 19px;
      font-weight: 500;
      & span{
        display: block;
        font-size: 18px;
        font-weight: normal;
        color: #6e7a81;
      }
    }
  }

  input[type="radio"] {
    display: none;

    + label {
      font-size: 30px;

      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 50%;
        border: solid 1px #e4e4ea;
        text-align: center;
        line-height: 30px;

        mat-icon {
          opacity: 0;
          transition: all .3s ease;
          width: 18px;
          height: 18px;
          line-height: 18px;
          font-size: 18px;
        }
      }
    }

    &.checked + label span {
      background-color: $primary-color;
    }

    &:checked + label span mat-icon {
      opacity: 1;
      color: #fff;
    }
  }
  .add-family-btn{
    button{
      background-color: $primary-color;
      color: #fff;
      font-size: 14px;
      padding: 10px 15px !important;
      margin-bottom: 20px;
      width: 100%;
      border-top-right-radius: 80px;
      border-top-left-radius: 80px;
      border-bottom-right-radius: 80px;
      border-bottom-left-radius: 80px;
      mat-icon{
        color: #fff;
      }
    }
  }
}

.example-checkbox{
  .mat-checkbox-layout{
    white-space: normal !important;
    .mat-checkbox-inner-container{
      margin-top: 3px !important;
    }
  }
}


.select-date_wrapper {
  app-select-appointment-date .select-appointment-inner {
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    @media all and (max-width: 460px) {
      flex-direction: column;
      .c-p.btn {
        width: 100%;
      }
    }
  }
}
.select-app-reg {
   app-select-appointment-date .select-appointment-inner {
    flex-direction: column;
   }
}

.mat-snack-bar-container.error-message{
  background-color: #f7d7da !important;
  color: #b06652 !important;
}
.mat-snack-bar-container.warning-message{
  background-color: #fff3cd !important;
  color: #8b7844 !important;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.66);
}

// for hiding state input
.move {
  position: absolute;
  right: 1000%;
  visibility: hidden;
}

ul.learnMore-list{
  padding-right: 8px;
}

qrcode img{
  width: 100%;
}




.main-container {
  width: 95%;
  margin: auto;
  max-width: 1920px;
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
}

.flex-grow-1 {
  flex-grow: 1!important;
}
@media(min-width:768px){
  .main {
    background: #f3f6f9;
  }
}

.no_carousel{

  @media (max-width:1280px){
  .upcoming-tasks{
    overflow-x: auto;
  }
  }
  @media (max-width:991px){
    display: none !important;
  }
}
@media (min-width:991px){
  .carousel_wrap{
    // display: none;
    width: 100%;
    display: block;
    &.notactiveslider{
      display: none;
    }
  }
  .no_carousel{
    display: block;
  }
}
.carousel_wrap{
  .owl-nav{
    .owl-prev,
    .owl-next{
      position: absolute;
      top: -27px;
      padding: 0;
      margin: -28px 0 0;
      display: block;
      width: 42px;
      height: 42px;
      text-indent: -9999px;
      background: $border-color;
      &:hover{
        background: none;
      }
      @media (max-width: 991px) {
        display: none;
      }
      @media(min-width:1680px){
        top: -40px;
      }
    }
    .owl-prev{
        right: 60px;
        background:url(../assets/cp-v2-assets/arrow-back.svg) center center no-repeat $section-background!important;
    }
    .owl-next{
        right: 0;
        background:url(../assets/cp-v2-assets/arrow-back.svg) center center no-repeat $section-background !important;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
    .disabled {
      opacity: .5;
      cursor: default;
    }
  }
}

.upcoming-tasks {
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
   .card_wrap{
     .card{
       height: 100%;
     }
    &:last-child {
      .card{
        margin-right: 0;
      }
    }
   }
  .card {
    max-width: 525px;
    min-height: 350px;
    display: flex;
    @media (max-width:991px){
      max-width: 100%;
    }
      &.complete{
        .card_body{
          border-color: #38b949;
      }
      .card-header{
          background: #cfffd5;
      }
    }
    @media (min-width:1280px) and (max-width:1600px){
      //max-width: 390px;
    }
  }
  .card_body {
    border-radius: 10px;
    border: solid 1px $border-color;
    background-color: #ffffff;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .card-header {
    background: $section-background;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 110px;
    img {
      margin-right: 22px;
      width: auto;
      height: 87px;
      @media (max-width:1460px){
        height: 75px;
      }
    }
    h3 {
      font-size: 20px;
      color: $heading-text;
      font-weight: 500;
      text-align: left;
      @media (max-width:1460px){
        font-size: 16px;
      }
    }
  }
  .card-wraper {
    padding: 6px 20px 20px;
    box-sizing: border-box;
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      display: block;
      margin: 0;
      padding: 2px 10px;
      width: 100%;
      box-sizing: border-box;
      @media (min-width:1680px){
        padding: 4px 10px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
      &.outline-btn {
        border-color: #323264;
        background: #fff;
        color: #323264 !important;
        span {
          color: #323264 !important;
        }
        mat-icon {
          color: #323264 !important;
        }
      }
      svg {
        margin-left: 10px;
      }
      mat-icon {
        color: #fff;
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
      &.mat-button-disabled {
        span {
          color: #b5b5b5;
          svg path {
            fill: #b5b5b5;
          }
        }
      }
      &.cancel-btn {
        border-color: $primary-color;
        color: $primary-color;
        min-width: 120px;
        span {
          color: $heading-text;
        }
      }
    }
  }
}

.i-address {
  font-size: 13px;
  display: inline-block;
}

.carousel_wrap .upcoming-tasks .card {
  max-width: inherit;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.topview {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  &.full_block {
    flex-wrap: wrap;
    .appointment {
      width: 100%;
      &.light_c {
            border-left: 1px solid #ccc;
            padding-left: 15px;
        span {
          color: $secondary-detail;
          strong {
            color: $heading-text;
            font-weight: 400;
          }
        }
      }
      svg, img {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
      span {
        a {
          color: $heading-text;
          font-weight: 500;
        }
        strong {
          display: inline;
        }
      }
      &:last-child span p { margin-bottom: 0;}
    }
  }
  .appointment {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    margin-top:15px;
    color: $heading-text;
    @media (max-width:1460px){
      font-size: 14px;
    }
    svg, img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    span {
      color: $heading-text;
      &.map_i {
        strong {
          position: relative;
          padding-right: 40px;
          img {
            width: 30px;
            position: absolute;
            right: 0;
            bottom: -4px;
          }
        }
      }
      strong {
        display: block;
        font-weight: 500;
        margin-top: 2px;
      }

      .datetag {
        background: #38b949;
        color: #fff;
        padding: 5px 14px;
        display: inline-block;
        border-radius: 22px;
      }
    }
    &.memb,
    &:last-child { width: 100%;}
    &.memb strong {
      font-size: 18px; margin: 0;
    }
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.filter_menu_wrap{
  background: #000;
    opacity: 0.5;
}
.filter_menu_wrap + * .cdk-overlay-pane{
  position: fixed !important;
  bottom: 0 !important;
  top: 0!important;
  right:0 !important;
  left: auto !important;
  .mat-menu-panel{
    max-height: 100%;
  }

  .filter_menu{
    .main_title{
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 37px;
      margin-top: 0;
    }
    .title{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}

@media(min-width:768px){
	.login-mode {
		.screening-assessment {
      margin-top: 3rem;
      border-radius: 10px;
      padding: 25px 47px !important;
      overflow: hidden;
      background: #fff;
    }
	}
}

.app_organizations {
  .service-margin-top {
    margin-top: 1rem !important;
  }
  app-agreement {
    &.h-100 {
      height: auto !important;
    }
    .agreement {
      margin-top: 1rem;
      border-radius: 10px;
      padding: 25px 47px !important;
      background: #fff;
      margin-bottom: 1rem;
    }
  }
  assessment-stepper {
    justify-content: flex-start !important;
    .screening-assessment {
      margin-top: 1rem;
    }
  }
  app-you-belong-to-tier {
    height: auto !important;
    margin-top: 1rem;
    border-radius: 10px;
    overflow: hidden;
    .agreement {
      padding-top: 1rem !important;
      background: #fff;
      .result_wrap {
        margin-bottom: 0;
      }
    }
  }
  app-register-form {
    height: auto !important;
    background: #fff;
    margin: 1rem 0;
    border-radius: 10px;
    padding: 40px 0 0;
  }
}

.ag-grid-hierarchy .ag-theme-alpine .ag-row-hover {
    cursor: pointer !important;
}
img.update-org-icon.ng-star-inserted {
    max-width: 100%;
}

.initiate_loading_spinner {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.read_only_bg{
  background-color: #f1f1f1 !important;
}

app-organizations app-org-overview .cura_loading_container {
  position: fixed;
  left: 60px;
  right: 0;
  background: #f3f6f9;
  top: 0;
}
.word-break{
  word-break: break-word;
}

.inline-size{
  inline-size: initial;
}


@media(max-width:767px){
  .insurance-information-w {
    .app-insurance-information {
      padding: 0 !important;
      .selection-dropdown-container {
        margin-bottom: 0 !important;
        flex-direction: column;
        flex: 1;
        .wrap-mat-form-field { width: 100%;}
      }
      .no-health-coverage {
        .mat-checkbox {
          margin: 1px 0px 0 0 !important;
        }
        .mat-checkbox-label {
          margin-left: 0 !important;
          white-space: break-spaces;
        }
        .selection-dropdown-container {
          .mat-radio-group{
            flex-direction: column;
            .mat-radio-button {
              margin: 10px 0;
            }
          }
          .wrap-mat-form-field.mr-3 {
            width: 100% !important;
            margin-bottom: 15px;
          }
        }

      }
    }
  }
  .geocode-address-w {
    .app-geocode-information {
      .form-row.flex-wrap {
        .w-130px {
          width: 100% !important;
          margin-right: 0 !important;
        }
      }
    }
  }
  .doctor-information-w {
    .mat-expansion-panel-body {
      .w-25 {
        width: 100% !important;
      }
    }
  }
}

.wg-message-snack-bar{
  background-color: white!important;
  color: $primary-color!important;
  border: 2px solid white;
  outline: $primary-color;
}
@media screen and (max-width: 360px) {
  .upcoming-tasks{
    .card-wraper{
      padding: 6px 8px 22px;
      button mat-icon{
        margin-left: 2px;
      }
    }
  }
}
