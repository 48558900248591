@import "cp-theme";

*.mat-drawer-container {
  color: $main_gray_09;
}

mat-form-field {
  width: 100%;
}
app-clinic-account-info span.mat-checkbox-label {
  font-size: inherit !important;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
	box-shadow: unset !important;
}

.bg-white {
    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
.filter-wrapper-checkboxes {
  .mat-checkbox {
    margin-top: 0 !important
  }
  .d-flex div:last-child .mat-checkbox{
    margin-right: 0 !important;
  }
}
.titlebar-filter-container {
  .mat-form-field-label-wrapper {
    line-height: initial;
    height: 130%;
  }
}
.mat-form-field-hide-placeholder .mat-select-placeholder {
  display: none !important;
}
th.h-0 {
  height: 0;
  min-height: unset;
}



.th-none.mat-table tr.mat-header-row {
  height: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.wrap-mat-form-field {
  .mat-form-field-label, .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper {
    position: static !important;
  }
  .mat-form-field-subscript-wrapper {
    margin-top: 3px !important;
  }
  .mat-form-field-infix {
    display: flex;
    flex-direction: column-reverse;
  }
  mat-hint {
    white-space: normal
  }
  .mat-select-value {
    vertical-align: middle;
  }
  .mat-form-field-label {
    transform: none !important;
    mat-label {
      padding: 0 !important;
      background: transparent;
      color: $main_gray_09;
      margin-bottom: 4px;
      display: block;
    }
  }
  &.mat-select-wrapper {
    .mat-form-field-label-wrapper {
      position: absolute !important;
      top: 8px;
      left: 12px;
        mat-label {
        color: $main_gray_06;
      }
    }
    .input_invalid + .mat-form-field-label-wrapper mat-label {
      color: $main_normal_red;
    }
  }
}

.mat-radio-group .mat-radio-label, .mat-option, .mat-select-trigger, .mat-select, .mat-form-field, .mat-slide-toggle-content,
.cdk-overlay-container input,
.mat-stepper-horizontal, .mat-stepper-vertical,
.mat-tab-group .mat-tab-header, .mat-tab-group .mat-tab-body-wrapper, .mat-tab-group .mat-tab-label-content, th.mat-header-cell.cdk-header-cell {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  line-height: 20px;
}
.mat-radio-group .fs-12 .mat-radio-label {
	font-size: 12px;
}

.cdk-overlay-container div,
.cdk-overlay-container span,
.cdk-overlay-container p {
	font-family: 'Roboto', sans-serif !important;
	line-height: normal;
}

.mat-tab-group *[class*="icon"] {
	font-family: 'icomoon', serif !important;
}

.mat-tab-group *[class*="mat-icon"] {
	font-family: 'Material Icons', sans-serif !important;
}

.mat-select-panel {
  .mat-option, .mat-option.mat-selected {
    &.language-option {
      background: transparent !important;
      padding: 10px 0 !important;
    }
  }
}

div.mat-form-field-subscript-wrapper, div.mat-form-field-subscript-wrapper .mat-error  {
	font-size: 11px !important;
    line-height: 14px !important;
    margin-top: 10px !important;
    width: auto !important;
    right: -5px;
    left: 0;
    white-space: nowrap;
}
.mat-form-field-appearance-legacy .mat-hint {
  color: $main_gray_08 !important;
}
.team-task {
  .mat-option, .mat-select-trigger, .mat-select, .mat-form-field, .cdk-overlay-container * {
    font-size: 13px;
  }

  .mat-input-element.mat-form-field-autofill-control {
    border: 0;
    background-color: transparent;
  }
}

.sm-select-wrapper {
  .mat-select-trigger {
    height: 30px;
    padding: 5px 15px;
    border-color: $main_gray_03;
  }
  * {
  	font-size: 12px !important;
  }
  & ~ .mat-form-field-label-wrapper {
  	//margin-top: 22px;
  	* {
	  	font-size: 12px !important;
	  }
  }
}
tr.mat-row, tr.mat-footer-row {
  overflow: clip;
}

.mat-row.cdk-row[style*="0"] {
	td {
		display: none;
	}
}

.team-task-column-line {
  .mat-input-element.mat-form-field-autofill-control {
    width: 125px;
    font-size: 13px;
    height: 30px;
    border: 0;
    background: #ffffff;
    box-shadow: inset 0 1px 5px 0 rgba(167, 179, 187, 0.5);
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 6px 15px;
    margin: 6px 0 0 0;
    box-sizing: border-box;
  }

  .mat-datepicker-toggle-default-icon path {
    fill: $main_lighter_blue;
  }
}

.mat-expansion-panel-content, .mat-expansion-panel-header, .mat-checkbox {
  font-family: 'Roboto', sans-serif !important;
}

mat-dialog-container {
	top: 80px;
	position: relative;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  margin-top: -80px;
}

.cdk-overlay-pane.prompt-dialog-container {
  min-width: 338px;
  margin-top: -20vh;
}

.mat-form-field-suffix {
  margin-right: 10px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif !important;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: unset !important;
}

.mat-snack-bar-container {
  background: $main_light_green !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;
  height: 60px;
  padding: 0 30px !important;
}

.mat-simple-snackbar {
  justify-content: center !important;
  align-items: center;
  height: 60px;
  span {
    font-size: 14px !important;
    font-weight: 500;
  }
}

.mat-option-disabled {
  opacity: 0.4;
}

.mat-select-panel:not(.ng-animating) {
  margin-left: 19px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100% !important;
  max-height: 30vh !important;
  max-width: 100% !important;
  // transform: translate(-2px, 44px) !important;
  position: relative;
  right: 0 !important;

  &.multiple-select {
    margin-left: 47px;
  }

}

.mat-cell.cdk-cell.cdk-column-expandedDetail.mat-column-expandedDetail > div > div:first-child {
  padding: 20px 20px 0;
}
.mat-cell.cdk-cell.cdk-column-expandedDetail.mat-column-expandedDetail > div > div:last-child {
  padding: 0 20px 20px;
}
mat-pseudo-checkbox.mat-option-pseudo-checkbox {
  margin-right: 14px;
}

/* this will hide the element while it's being animated because
   the animation happens for the original position */

.mat-select-panel.ng-animating {
  transform: unset !important;
  visibility: hidden;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.mat-form-field-label-wrapper {
	//max-height: 30px !important;
    padding-top: 0;
    //top: 4px;
	label {
		height: 100% !important;
		display: flex;
		align-items:center;
	}
}

app-key-team-members, .departments_list {
	.mat-form-field-label-wrapper {
		//top:10px;
	}
}

.second-title-bar, .filter-on-white {
	.mat-form-field-infix {
		min-width: 160px !important;
	}
}
app-ae-patient-appointment .mat-form-field-label {
  align-items: center; display: flex !important;
}
.mat-form-field-infix {
	min-width: 100px !important;
	width: 100% !important;
	border-top: 0;
  padding: 0 !important;
	.mat-input-element {
		margin: 0 !important;
	}
	label.input_label ~ .mat-form-field-label-wrapper {
		bottom: 0 !important;
		top: auto !important;
	}

  .input-common, .mat-input-element {
    /*  DON'T CHANGE HERE ANYTHING !!!! Add new rules only to inner SCSS */
    height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    outline: none;
    border: solid 1px $main_gray_08;
    margin: 5px 0 10px 0;
    box-sizing: border-box;

    &::placeholder {
      color: $main_gray_05;
    }

    &:focus {
      border: 2px solid $main_normal_blue;
    }

    &.input_invalid {
      border: 1px solid $main_normal_red !important;
    }
	}
	textarea {
		&.input-common, &.mat-input-element {
			padding: 8px 10px;
		}
	}
  .mat-select:not(.label-margin-zero) {
    & + .mat-form-field-label-wrapper {
      .mat-form-field-label {
        margin-top: 3px;
      }
    }
  }
  .input_label ~ .input-common {
		margin-bottom: 0 !important;
	}
}
.mat-form-field-appearance-legacy .mat-form-field-infix textarea.mat-input-element + .mat-form-field-label-wrapper .mat-form-field-label {
	top: 30px !important;
}

assessment-question {
  .mat-form-field-appearance-legacy .mat-form-field-infix textarea.mat-input-element + .mat-form-field-label-wrapper .mat-form-field-label {
    top: 20px !important;
  }
  .mat-form-field-infix .mat-select + .mat-form-field-label-wrapper .mat-form-field-label {
    margin-top: 0 !important;
  }
  .mat-expansion-panel-body form {padding-top: 20px !important;}
}


.expiration_date {
  .mat-form-field-wrapper {
    padding: 0 0 0 10px !important;
    width: 135px;
  }

  .mat-input-element.cdk-text-field-autofilled, .mat-input-element.mat-form-field-autofill-control.cdk-text-field-autofill-monitored, .mat-input-element:focus {
    //background-color: #fff;
    z-index: 1;
    position: relative;
  }
}
app-birth-day-calendar {

  .mat-form-field-wrapper {
  	.mat-form-field-flex {
    	align-items: center !important;
    }
  }
  .mat-input-element.cdk-text-field-autofill-monitored.ng-dirty.ng-touched.ng-valid,  .mat-input-element.ng-untouched.ng-pristine.ng-valid {
	background-color: #fff;
  }

  .mat-input-element.cdk-text-field-autofilled, .mat-input-element.mat-form-field-autofill-control.cdk-text-field-autofill-monitored, .mat-input-element:focus {
    z-index: 1;
    position: relative;
  }
  .mat-form-field-infix {
	  border-top: 0;
	  padding: 0 !important;
	  min-width: 160px;
	  width: auto;

	  .input-common, .mat-input-element {
	  	max-width: 96%;
	  }
	}
}

.input-common {
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  max-width: 96%;
  outline: none;
  border: solid 1px $main_gray_08 !important;
  margin: 5px 0 10px 0;

  &::placeholder {
    color: $main_gray_05;
  }

  &:focus {
    border: 2px solid $main_normal_blue;
  }

  &.input_invalid {
    border: 1px solid $main_normal_red !important;
  }
}

app-birth-day-calendar .mat-form-field-wrapper, .expiration_date .mat-form-field-wrapper, app-specialties-chip .mat-form-field-wrapper {
	position: relative;
  width: auto;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.mat-select-trigger {
  position: relative;
  width: auto;
  height: 40px;
  //background: #ffffff;
  border: solid 1px $main_gray_08;
  border-radius: 4px;
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;

  &:focus {
    border: 2px solid $main_normal_blue;
  }
}

.input_invalid {
  app-specialties-chip .mat-form-field-wrapper {
    border-color: $main_normal_red !important;
    border-width: 1px !important;
  }
  .mat-select-trigger, .mat-form-field-wrapper {
    border-width: 0 !important;
  }
}

app-specialties-chip .mat-form-field-wrapper {
  margin-bottom: 0.5rem;
  padding-top: 0 !important;
  height: auto;
}

.mat-select-content {
  padding: 10px 0;
}

.mat-option {
  font-size: 14px;
  color: $main_gray_09 !important;
  height: 40px !important;

  &.mat-active {
    background-color: #fff;
  }

}

// fix mat-select-placeholder position in some cases

.edit-patient-profile-wrapper, .cdk-overlay-container {
    .selection-dropdown-container {
      .mat-select-placeholder {
        position: absolute;
        margin-top: 10px;
      }
      .mat-form-field-appearance-legacy mat-datepicker ~ .mat-form-field-label-wrapper .mat-form-field-label {
        top: 50% !important;
      }
    }
}


.mat-form-field-appearance-legacy .mat-form-field-label {
  /*
  Don't change ANYTHING here!!!
  You should only work with internal SCSS files under nested rules.
  */
  left: 10px; // इसे मत बदलो !!! DON'T CHANGE IT !!!
  top: 55%;
  transform: translateY(-50%);
  font-size: 14px;
  color: $main_gray_05;
  font-weight: 400;
}

.mat-form-field-appearance-legacy.search-patient .mat-form-field-label {
  margin-left: 20px;
}

.mat-select-arrow {
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  color: $main_gray_07;
}


.mat-form-field.mat-focused .mat-form-field-label, .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  //display: none;
  color: $main_gray_05 !important;
  font-size: 14px;
}
app-title-bar-filters, app-dropdown, app-birth-day-calendar {
  .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none !important;
  }
}
.mat-form-field-can-float.mat-form-field-should-float, .mat-form-field.mat-focused {
  label.input_label, div.label, input[type="search"] {
    & ~ mat-form-field .mat-form-field-label, & ~ .mat-form-field-label-wrapper label{
      display: none !important;
    }
  }
}
.input_container .label, label, *[class*="label"] {
  & ~ .mat-form-field.mat-focused .mat-form-field-label, & ~ .mat-form-field-should-float .mat-form-field-label,  & ~ * .mat-form-field.mat-focused .mat-form-field-label, & ~ * .mat-form-field-should-float .mat-form-field-label{
    display: none !important;
  }
}
/* .patient-registration-wrapper {
  .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: block !important;
  }
} */

mat-label.input_label, mat-label, .mat-form-field.mat-focused .mat-form-field-label span:not(.required-asterisk), .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label span:not(.required-asterisk){
  background: #fff;
  padding: 7px;
}


.mat-pseudo-checkbox {
  width: 12px !important;
  height: 12px !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(121, 129, 143, 0.5);
  background-color: #ffffff;
  border: 0 !important;
}

.mat-pseudo-checkbox-checked::after {
  top: 2px !important;
  left: 2px !important;
  width: 6px !important;
  height: 3px !important;
  border-left-width: 2px !important;
}

.mat-select-value-text {
  //background: #fff;
  position: absolute;
  z-index: 1;
  right: 37px;
  left: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
 -ms-transform: translateY(-50%);
 transform: translateY(-50%);
}
.cdk-overlay-container .mat-expansion-panel-body .mat-select-value {
  vertical-align: middle;
}


mat-icon {
  cursor: pointer;
}

.mat-icon {
  color: $main_normal_blue;
}

.mat-button-disabled{
  .mat-icon{
    color: $secondary-detail;
  }
}

.mat-tooltip {
  box-shadow: $b-shadow;
  border-radius: 4px;
  background-color: #fff;
  color: #444 !important;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  max-width: 200px;
}

.mat-option-text {
  display: flex;
  margin: 10px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid $main_gray_04;
    margin-right: 15px;
  }
  .mat-form-field-label {
		top: 23px !important;
		left: 0 !important;
	}
	.mat-input-element {
		margin: 0;
		padding: 0;
		border: unset !important;
	}
}

app-billing-filter mat-form-field {
  border: 1px solid $main_gray_05;
  padding: 3px;
  border-radius: 4px;
  width: 96%;

  input.mat-chip-input.mat-input-element {
    &::placeholder {
      color: $main_gray_05 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      visibility: visible;
      opacity: 1;
    }
  }

}

.mat-chip.mat-standard-chip {
  color: #fff !important;
  background-color: $main_normal_blue !important;
  border-radius: 4px;

  .mat-chip-remove {
    color: #fff !important;
  }
}

.mat-standard-chip.mat-chip-with-trailing-icon {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}


.spec-container {
  mat-chip-list {
		width: 100%;
		.mat-form-field-wrapper {
			width: 100%;
		}
	}
  mat-form-field.mat-form-field {
    .mat-form-field-wrapper {
      width: 100%;
      box-sizing: border-box;
    }

    .mat-form-field-flex {
      width: 100% !important;
    }

    .mat-form-field-infix {
      display: flex !important;
      width: 100% !important;
      padding: 3px;
    }

    .mat-chip-list {
      min-height: 38px;
      display: flex;

      &.input_invalid {
        border-radius: 4px;
        box-shadow: inset 0 1px 5px 0 rgba(167, 179, 187, 0.5);
        padding: 5px 15px;
        width: 74%;
        box-sizing: border-box;
        display: flex;
      }

    }
  }
}

.calendar {
  .cell-totals {
    display: flex;
    font-size: 12px;
    margin: 0 0 10px 1rem;
    font-weight: 500;
    flex-direction: column;
    max-height: 80px;
    overflow: auto;
  }

  .cal-month-view .cal-day-badge {
    background-color: $main_normal_blue;
    margin: 0.95rem 0.95rem 0 0;
    float: right;
  }

  .cal-month-view .cal-day-cell.cal-today {
    background-color: $main_normal_blue;

    .cal-day-number {
      line-height: 26px;
      font-weight: 500;
      color: #fff !important;
    }

    .cal-day-badge {
      background-color: #fff;
      color: #000;
    }

    .cell-totals {
      color: #fff;
    }

  }

  .cal-month-view .cal-open-day-events {
    color: $main_gray_09;
  }

  .cal-open-day-events {
    background-color: $border-color;
    box-shadow: unset;

    .cal-event-title {
      p {
        display: flex;
        align-items: center;
        margin: 0;

        img {
          width: 50px;
          height: auto;
          border-radius: 100px;
          margin-right: 30px;
        }

        span {
          margin: 0 16px 0 0;
          padding: 0 16px 0 0;
          border-right: 1px solid $main_gray_06;

          &:last-child {
            border: 0;
          }
        }
      }
    }

    .cal-drag-active {
      opacity: 0.8;
    }

    & > div {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.5rem;
      background-color: $main_gray_02;
      box-shadow: 0 0 5px #dfe7f2;
      margin: 5px 0;
    }

    .cal-event {
      margin-right: 20px;
    }
  }

  .cal-week-view {
    .cal-event-title {
      p {
        margin: 0;
        padding-top: 2px;
        white-space: normal;
        line-height: 11px;

        span {
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .cal-day-view {
    .cal-event-title {
      p {
        margin: 0;
        padding: 0;
        line-height: 10px;
        white-space: normal;

        img {
          width: 20px;
          border-radius: 30px;
          overflow: hidden;
          height: auto;
          margin-right: 5px;
          float: left;
        }

        span {
          width: calc(100% - 30px);
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border: 0;
}

.mat-button-toggle-group {
  box-shadow: unset !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  height: 28px;
  border: 0;
  font-size: 14px;
  align-items: center;

  .mat-button-toggle-label-content {
    line-height: 28px !important;
    padding: 0 12px;
    font-weight: 400;
    font-size: 14px;
  }

  .mat-button-toggle {
    margin-right: 0;
    border-radius: 4px;
    height: 28px;
    color: $main_gray_07;

    &.mat-button-toggle-checked {
      color: #fff;
      font-weight: 500;
      background-color: var(--primary);
    }
  }

  .mat-button-toggle-ripple {
    div {
      opacity: 0 !important;
    }
  }
}

.mat-form-field-type-mat-chip-list {
  .mat-form-field-infix {
    width: 100%;
  }
}

// stepper

.app-care-plan-task-add {
  .mat-vertical-stepper-header .mat-step-icon, .mat-vertical-stepper-header .mat-step-icon-not-touched {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  .mat-vertical-stepper-header .mat-step-icon {
    background-color: $main_light_green;
  }

  .mat-vertical-stepper-header .mat-step-icon-not-touched {
    background-color: $main_gray_03;
  }

  .mat-step-icon-content {
    font-weight: 500;
    font-size: 12px;
  }

  .mat-step {
    border: 1px solid $main_gray_06;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
  }

  .mat-stepper-vertical-line::before {
    display: none;
  }

  .mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
    font-size: 16px;
  }

  .mat-vertical-content-container {
    margin-left: 0;
  }

  mat-button-toggle {
    margin-right: 10px;
  }

.icons-mat-rewrite {
	.mat-icon {
		margin-right: -20px;
		&:before {
			content: "\e92b";
			font-size: 16px;
			color: $main_gray_09;
		}
		&.expand {
			display: flex;
			justify-content: center;
			margin-top: 4px;
	    align-items: center;
		}
		&.expanded {
			transform: rotate(90deg);
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 5px;
		}
	}

}



  .mat-step-icon .mat-icon {
    font-size: 14px;
    color: #fff;
    height: 14px;
    width: 14px;
    font-weight: normal;
  }

  .datatable-body {
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  [class*="datatable-body-cell"] {
    width: 91%;
  }

  .mat-step:nth-child(2) .mat-vertical-content .datatable-body-row {
    background-color: $main_gray_01;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  .mat-step:nth-child(3) .mat-vertical-content .datatable-body-row {
    border-top: 1px solid $main_gray_06;
    border-left: 1px solid $main_gray_06;
  }

  .datatable-body-row.active {
    background-color: $main_normal_blue !important;

    & * {
      color: #fff;
    }
  }

  .datatable-body-row.active .datatable-row-group:hover {
    background-color: transparent !important;
  }

  .datatable-body-cell {
    border: 0;
  }

  .mat-vertical-stepper-header {
    padding: 18px;

    &:hover {
      background-color: transparent;
    }
  }

  .mat-vertical-content {
    margin-top: 10px;

    &:before {
      content: '';
      margin: -11px 20px 0;
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: 1px solid $main_gray_04;
    }
  }

  .mat-step-label {
    width: 93%;
    display: flex;
    align-items: center;

    span {
      display: inline-flex;
      white-space: normal;

      &:first-child {
        width: 12%;
      }

      &:nth-child(2) {
        width: 32%;
      }
    }
  }

}

// mat-checkbox
.mat-checkbox {
  margin: 15px 40px 0 0;
  display: inline-block;
}

.mat-checkbox-frame {
  border: 1px solid #d3d8e0 !important;
  box-shadow: inset 0 1px 3px 0 rgba(121, 129, 143, 0.5);
  border-radius: 3px;
  box-sizing: border-box;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $main_normal_blue !important;
  border-radius: 3px;
}

.mat-checkbox-inner-container {
  width: 14px;
  height: 14px;
}

.mat-vertical-stepper-content {
  .mat-checkbox {
    width: calc(100% / 3);
    margin-right: 0;
  }
}

mat-checkbox.checkbox-large.mat-checkbox {
  margin: 0.5rem 1rem 0 0;

  .mat-checkbox-inner-container {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid $main_gray_07 !important;
    border-radius: 4px;
  }

  * {
    box-shadow: unset !important;
    border: unset !important;
  }

  &.mat-checkbox-checked .mat-checkbox-inner-container {
    border-width: 0 !important;
  }
}

mat-checkbox.checkbox-middle.mat-checkbox {
  margin: 0 0 0 15px;

  .mat-checkbox-inner-container {
    width: 20px !important;
    height: 20px !important;
    border: 1px solid $main_gray_07 !important;
    border-radius: 4px;
  }

  * {
    box-shadow: unset !important;
    border: unset !important;
  }

  &.mat-checkbox-checked .mat-checkbox-inner-container {
    border-width: 0 !important;
  }
}

app-agreement .mat-checkbox-layout {
  text-align: left;
  white-space: unset;
}

.input-common.mat-autocomplete-panel {
  height: 160px;
}


.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: $main_normal_blue !important;
}

.aq-multiple_choice {
  .mat-radio-label {
    margin-bottom: 1rem;

    .mat-radio-label-content {
      font-family: 'Roboto', sans-serif !important;
      font-size: 13px;
    }

    .mat-radio-container {
      zoom: 0.6;
    }

    .mat-radio-outer-circle {
      border-color: $main_normal_blue;
    }
  }

  .mat-radio-button.mat-radio-disabled {
    .mat-radio-label {
      .mat-radio-label-content {
        color: $main_gray_09;
      }
    }

    .mat-radio-inner-circle, .mat-radio-ripple .mat-ripple-element {
      background-color: $main_normal_blue !important;
    }

    .mat-radio-outer-circle {
      border-color: $main_normal_blue !important;
    }

    &.mat-radio-checked .mat-radio-inner-circle {
      transform: scale(1) !important;
      position: relative;

      &:before {
        content: '\e90c';
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: "icomoon", serif;
        font-size: 10px;
        position: absolute;
        border-radius: 100%;
        height: 20px;
        left: 0;
        top: 0;
        width: 20px;
        background: $main_normal_blue;
      }
    }
  }
}

.performance-container {
  .filter-days-week {
    mat-checkbox.mat-checkbox {
      margin: 0;
      width: 76px;
      padding-bottom: 10px;
      box-sizing: border-box;

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $main_gray_08;
        font-size: 12px;

        .mat-checkbox-inner-container {
          margin: 0 0 8px;

          .mat-checkbox-frame {
            border-color: var(--primary) !important;
            box-shadow: inset 0 0 2px 0 rgba(121, 129, 143, 0.5) !important;
            border-radius: 3px;
          }
        }

        .mat-checkbox-label {
          white-space: normal;
          text-align: center;
          line-height: 14px;
        }
      }

      &.active {
        border-bottom-color: $main_normal_blue;

        label {
          color: $main_gray_09;
        }
      }
    }
  }
}

div.titlebar-filter-container {
  .mat-form-field-appearance-legacy mat-datepicker ~ .mat-form-field-label-wrapper .mat-form-field-label {
    top: 53% !important;
  }
  .mat-form-field-infix {
    .mat-select {
      & + .mat-form-field-label-wrapper {
        .mat-form-field-label {
          margin-top: 0 !important;
        }
      }
    }
  }
  @-moz-document url-prefix() {
    .mat-form-field-infix {
      .mat-select {
        & + .mat-form-field-label-wrapper {
          .mat-form-field-label {
            margin-top: -3px !important;
          }
        }
      }
    }
  }
}
.provider-details-form-wrapper, .add-event-wrapper, app-geocode-address {
  .mat-form-field-infix {
      .mat-select {
        & + .mat-form-field-label-wrapper {
          .mat-form-field-label {
            margin-top: 0 !important;
          }
        }
      }
    }
}
.care-team-tasks-table {
  tbody {
    .mat-row:hover {
      td {
        background-color: #F9FAFB;
      }
    }
  }

  mat-form-field.input_invalid {
    .mat-form-field-infix {
      border: 1px solid $main_normal_red !important;

      * {
        color: $main_normal_red;
      }
    }
  }

  mat-select.input_invalid *, .input_invalid + .mat-form-field-label-wrapper .mat-form-field-label {
    color: $main_normal_red !important;
  }

  .mat-form-field-infix {
    height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    outline: none;
    border: solid 1px $main_gray_08;
    margin: 0 15px 0 0;
    width: auto;

    &::placeholder {
      color: $main_gray_05;
    }

    &:focus {
      border: 2px solid $main_normal_blue;
    }

    .mat-select {
      min-width: 133px;
    }

    .mat-select-trigger {
      border: 0;
    }

    input {
      height: 100%;
      padding-left: 10px;
      color: $main_gray_10;

      &[type="time"] {
        height: 20px;
        padding-top: 12px;
        width: calc(100% - 20px);
        min-width: 65px;
      }
    }
  }
}

.questions-container {
  .mat-radio-outer-circle {
    border-width: 1px;
  }
}

.data-toggle-like-tabs {
  app-date-toggle {
    height: 60px;

    & > .d-flex {
      align-items: center;
    }
  }

  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
    display: none;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
    margin: 0 0 0 20px;
  }

  * {
    border-radius: 0 !important;
  }

  mat-button-toggle-group {
    height: 100%;
    align-items: flex-end;
  }

  .mat-button-toggle-group .mat-button-toggle {
    color: $main_gray_07;
    font-size: 14px;
    display: inline-flex;
    height: 45px;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    &.mat-button-toggle-checked {
      background: transparent !important;
      color: $main_gray_09;
      position: relative;
      cursor: default;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 4px;
        bottom: 0;
        left: 20px;
        right: 0;
        background: $main_normal_blue;
      }
    }
  }
}


.title-container, .pop_options {
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid $main_gray_06;
  padding-left: 1rem;
  font-weight: 500;

  ul {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .title, .title_box {
    color: $main_gray_07;
    font-size: 14px;
    display: inline-flex;
    height: 50px;
    margin: 0 15px;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    &:not(.active):hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: $main_gray_09;
      position: relative;
      cursor: default;

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $main_normal_blue;
      }
    }
  }
}


.mat-table {
  box-shadow: unset;
  width: 100%;

  tr.mat-header-row {
    height: 36px;
  }

  .mat-row {
    &.hovered_item, &.active {
      .mat-cell {
        background-color: $main_gray_01;
      }
    }
  }

  .mat-row.hovered_item {
    cursor: pointer;
  }

  .mat-cell {
    height: 50px;
    max-height: unset;
    color: $text_dark;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    overflow-x: auto;
    vertical-align: middle;

    & > span {
      display: inline-block;
      max-height: 100px;
      max-width: 30vw;
    }

    & > img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin: 0 15px 0 0;
      border: 1px solid $main_gray_06;
      vertical-align: middle;

      &.w-40 {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        margin: 0 15px;
      }

      &.w-30 {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        margin: 0 15px 0 0;
      }
    }
  }
}

.team-members, app-org-events-overview, app-org-providers-table {
  .mat-table .mat-row.hovered_item {
    cursor: default;
  }
}

app-org-uploads {
  .mat-table .mat-cell > span {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 800px);
    font-size: 11px;
  }
  .mat-tab-group.mat-primary .mat-tab-header {
    height: auto;
    .mat-tab-labels {
      flex-wrap: wrap;
    }
  }
}


.mat-table + mat-paginator.mat-paginator {
  .mat-paginator-container {
    min-height: 46px;
    display: flex;
    align-items: center;

    * {
      font-family: 'Roboto', sans-serif !important;
      font-size: 12px !important;
      line-height: normal !important;
      color: $main_gray_07 !important;
    }

    .mat-select-trigger {
      border: 0 !important;
      min-width: 72px;
    }
  }
}

.cdk-overlay-pane .mat-select-panel:not(.ng-animating) {
  //transform: translate(-2px, -35px) !important
}

.second-title-bar {
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $main_gray_07;
    font-size: 12px !important;
    text-transform: capitalize;
  }

  .titlebar-search-container {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin-right: 2rem;

    .mat-form-field-flex {
      align-items: center !important;
    }

    .mat-form-field-infix {
      width: 300px;
      max-width: unset;

      .search-input.mat-input-element {
        min-width: 300px;
        max-width: unset;
        font-size: 12px !important;
        height: 30px;
        border: 1px solid $main_gray_03;
        border-radius: 4px;
        padding-left: 10px;
      }
    }

    .mat-select-placeholder {
      color: $main_gray_07;
      font-size: 12px !important;
    }

    .mat-form-field-suffix .mat-icon {
      color: $main_gray_03 !important;
      font-size: 180% !important;
      margin-left: 10px;
    }
  }

  .titlebar-filter-container {
    .mat-select-trigger {
      font-size: 12px !important;
      line-height: 28px;
      height: 30px;
      border: 1px solid $main_gray_03;
      border-radius: 4px;
      padding: 0 !important;
    }
  }


}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $main_normal_blue !important;
}
.mat-radio-outer-circle {
	border-color: $main_gray_07 !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: $main_normal_blue !important;
}


.mat-cell {
  .circle {
    background: transparent;
    padding: 0;
    min-width: unset;
  }
}

.mat-tab-group.mat-primary {
	&:not(.border-none) {
		.mat-tab-header {
			border-bottom: 1px solid #dfe7f2;
		}
	}
	.mat-tab-header {
		height: 50px;
		flex-direction: row;
		align-items: flex-end;
	    padding-left: 1rem;
	    font-weight: 500;
	    font-size: 15px;
	}
	.mat-ink-bar {
		opacity: 0;
		height: 4px;
		background-color: var(--primary);
	}
	.mat-tab-label-active .mat-tab-label-content{
		color: $main_normal_blue !important;
	}
	.mat-ripple-element {
		opacity: 0 !important;
	}
	.mat-tab-label * {
		color: $main_gray_08 !important;
	}
	.mat-tab-nav-bar, .mat-tab-header {
		border: 0;
	}
	.mat-tab-label {
    opacity: 1 !important;
		min-width: unset !important;
		font-weight: 500;
		font-size: 15px !important;
		text-transform: capitalize;
		padding: 0 !important;
		margin: 0 10px !important;
	}
}

// modals
mat-dialog-container.mat-dialog-container {
    padding: 0 !important;
    min-width: 80vw;
    overflow: inherit;
    background-color: #ffffff;
  }

@media all and (min-width: 960px) {
  mat-dialog-container.mat-dialog-container {
    min-width: 400px;
  }
}

.mat-dialog-content {
	margin: 0 !important;
	box-sizing: border-box;
	max-height: 70vh !important;
	@media all and (max-height: 960px) {
		max-height: 50vh !important;
	}
}
.mat-dialog-actions {
	margin-bottom: 0 !important;
	padding: 20px 30px !important;
	box-sizing: border-box;
	border-top: 1px solid $main_gray_06;
	justify-content: flex-end;
	margin-top: 20px;
}
.cdk-overlay-container * .mat-dialog-actions {
  .footer, .button_container, .pagination_container{
    .mat-button-wrapper {
      font-size: 12px !important;
    }
  }
}

.cdk-overlay-container {
	*[class*="input"], .input-common, .mat-select-trigger, app-birth-day-calendar .mat-form-field-wrapper, .expiration_date .mat-form-field-wrapper, app-specialties-chip .mat-form-field-wrapper, .input_container input[class*=_field] {
		border-color: $main_gray_05 !important;
		box-sizing: border-box;
		&:focus {
	      border: 2px solid $main_normal_blue !important;
	    }
	}
	.input_invalid:not(.unstyled-list):not(.nored):not(.mat-radio-group):not(.google-autocomplete):not(.mat-checkbox) {
		border: 1px solid #e74c3c !important;
		box-sizing: border-box;
    overflow: hidden;
		&:focus {
	      border: 2px solid $main_normal_blue !important;
	    }
	}
  app-google-autocomplete.input_invalid:not(.unstyled-list):not(.nored) {
    border-width: 0 !important;
  }
	app-birth-day-calendar, app-specialties-chip {
		&.input_invalid, mat-form-field .mat-form-field-wrapper {
			border-width: 0 !important;
		}
	}
	app-birth-day-calendar.input_invalid, app-dropdown.input_invalid {
		.mat-input-element {
			border: 1px solid #e74c3c !important;
		}
    .mat-form-field-label {
      color: $main_normal_red;
    }
	}
	app-dropdown, *[class*="input"], .input-common, app-birth-day-calendar {
		margin-top: 0 !important;
	}
	.mat-chip-list-wrapper {
		width: 100%;
		margin: 0 !important;
	}
}

.mat-sort-header-button {
	text-align: left;
}

.mat-cell.cdk-cell.cdk-column-name.mat-column-name {
	display: flex;
    align-items: center;
}
.cdk-cell.cdk-column-providerImage.mat-column-providerImage, .cdk-cell.cdk-column-image.mat-column-image {
	width: 50px;
}
.departments_list {
	.mat-cell.cdk-cell.cdk-column-name.mat-column-name {
	    vertical-align: text-top !important;
    	padding-top: 8px;
    	display: table-cell;
	}
}
.mat-elevation-z8 {
	.mat-cell.cdk-cell.cdk-column-name.mat-column-name {
	    vertical-align: middle;
    	display: table-cell;
    	white-space: nowrap;
    	padding-right: 10px;
    	max-width: 250px;
	}
	.w30x30 {
		flex: 0 0 30px;
		width: 30px;
		min-width: 30px;
		overflow: hidden;
	}
}
app-cp-task-add-dialog .mat-elevation-z8 .mat-cell.cdk-cell.cdk-column-name.mat-column-name {
  white-space: normal;
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit, .mat-step-header .mat-step-icon-state-edit .mat-icon{
	color: #fff !important;
	background-color: $main_normal_blue !important;
}
.mat-step-header .mat-step-icon {
	background-color: $main_gray_05;
}
.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
	color: $main_gray_05;
}
.mat-vertical-content {
	margin-top: 20px;
}

.mat-expansion-panel {
	background-color: #FAFCFE;
}
.mat-expansion-panel-body {
	padding-top: 20px !important;
	//border-top: 1px solid #dfe7f2;
}
.mat-expansion-panel-header {
	background-color: #fff;
	font-size: 16px;
	font-weight: 500;
  * {
		transition: all .0s !important;
	}
}
.mat-expansion-panel-spacing:not(.org_list_item) {
	margin: 0 0 1rem !important;
	box-shadow: none !important;
}

button.relative mat-progress-spinner.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] {
	position: absolute !important;
    top: 31%;
    left: 0;
    right: 0;
    margin: auto;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: $aqua-marine;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar, .mat-slide-toggle-bar {
	background-color: #d2dbe1;
}
mat-progress-bar.mat-progress-bar {
  height: 6px;
  border-radius: 10px;
}

.mat-progress-bar-fill::after {
  background-color: $main_normal_blue;
}

.mat-progress-bar-buffer {
  background: $main_gray_01;
}

.mat-form-field-appearance-legacy mat-datepicker ~ .mat-form-field-label-wrapper .mat-form-field-label{
	top: 59% !important;
}
.mat-paginator-container {
  justify-content: flex-start !important;
}

.table-failed-rows {
  width: calc(100vw - 300px - 220px);
  .mat-column-error {
    width: 300px;
  }
  .mat-column-row {
    div {
      width: calc(100vw - 300px - 552px);
      code {
        word-break: break-all;
      }
    }
  }
}

// datepicker
$calendar-padding: 8px !default;
$calendar-header-divider-width: 1px !default;
$calendar-controls-vertical-padding: 5%;
// We use the same padding as the month / year label, but subtract 16px since there is padding
// between the edge of the button and the text. This ensures that the button text lines up with
// the month / year label text.
$calendar-controls-side-margin: calc(33% / 7 - 16px);

$calendar-arrow-size: 5px !default;
$calendar-arrow-disabled-opacity: 0.5 !default;

// Values chosen to approximate https://material.io/icons/#ic_navigate_before and
// https://material.io/icons/#ic_navigate_next as closely as possible.
$calendar-prev-next-icon-border-width: 2px;
$calendar-prev-next-icon-margin: 15.5px;
$calendar-prev-icon-transform: translateX(2px) rotate(-45deg);
$calendar-next-icon-transform: translateX(-2px) rotate(45deg);


.mat-calendar {
  display: block;
  background: #fff;
}

.mat-calendar-header {
  padding: $calendar-padding $calendar-padding 0 $calendar-padding;
}

.mat-calendar-content {
  padding: 0 $calendar-padding $calendar-padding $calendar-padding;
  outline: none;
}

.mat-calendar-controls {
  display: flex;
  margin: $calendar-controls-vertical-padding $calendar-controls-side-margin;

  .mat-icon-button:hover .mat-button-focus-overlay {
    opacity: 0.04;
  }
}

.mat-calendar-spacer {
  flex: 1 1 auto;
}

.mat-calendar-period-button {
  min-width: 0;
}

.mat-calendar-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: $calendar-arrow-size solid transparent;
  border-right: $calendar-arrow-size solid transparent;
  border-top-width: $calendar-arrow-size;
  border-top-style: solid;
  margin: 0 0 0 $calendar-arrow-size;
  vertical-align: middle;

  &.mat-calendar-invert {
    transform: rotate(180deg);
  }

  [dir='rtl'] & {
    margin: 0 $calendar-arrow-size 0 0;
  }
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
  position: relative;

  &::after {
    content: '';
    margin: $calendar-prev-next-icon-margin;
    border: 0 solid currentColor;
    border-top-width: $calendar-prev-next-icon-border-width;
  }

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
}

.mat-calendar-previous-button::after {
  border-left-width: $calendar-prev-next-icon-border-width;
  transform: $calendar-prev-icon-transform;
}

.mat-calendar-next-button::after {
  border-right-width: $calendar-prev-next-icon-border-width;
  transform: $calendar-next-icon-transform;
}

.mat-calendar-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.mat-calendar-table-header th {
  text-align: center;
  padding: 0 0 $calendar-padding 0;
}

.mat-calendar-table-header-divider {
  position: relative;
  height: $calendar-header-divider-width;

  // We use an absolutely positioned pseudo-element as the divider line for the table header so we
  // can extend it all the way to the edge of the calendar.
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -$calendar-padding;
    right: -$calendar-padding;
    height: $calendar-header-divider-width;
  }
}
.appointment-info.mat-tab-group.mat-primary .mat-tab-label-active .mat-tab-label-content {
  &:hover {
    cursor: default;
  }
  &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $main_normal_blue;
      }
    }

mat-accordion.mat-accordion.mat-accordion-design-vaccination {
    width: 100%;
  margin: 30px 0 10px;
  position: relative;
  .mat-expansion-panel {
    border:1px solid #beceed;
    margin-bottom: 10px;
    border-radius: 10px;
    .mat-expansion-panel-header {
      font-size: 16px;
      height: auto;
      min-height: 68px;
      .mat-expansion-panel-header-title {
        color: #16152e;
        align-items: center;
      }
      .mat-expansion-panel-header-description {
        font-size: 14px;
        align-items: center;
      }
    }
  }
  .mat-content {
    overflow: visible;
    flex-direction: column;
  }
  .mat-expansion-indicator {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50px;
    background-color: $main_normal_blue;
    align-items: center;
    justify-content: center;
    margin: 0 3px 0 0;
    opacity: 0.2;
  }
  .mat-expansion-indicator::after {
    color: #fff !important;
    transform: rotate(-45deg);
  }
  .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator {
    opacity: 1;
    transform: rotate(-90deg) !important;
  }
    .mat-expansion-panel-body {
      padding: 0 !important;
      border-radius: 0 0 10px 10px;
    }
}
