@import "cp-theme";

ng5-slider.ng5-slider {
  .ng5-slider-bar {
    height: 8px;
  }
  .ng5-slider-selection {
    background: $aqua-marine;
  }

  .ng5-slider-pointer {
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    top: -2px;
    background-color: #fff;
    border: 2px solid $aqua-marine;
    border-radius: 50px;
    outline: none !important;
    &:before {
      content:'';
      width: 10px;
      height: 10px;
      background-color: $aqua-marine;
      top: -20px;
      position: absolute;
      left: 0;
      transform: rotate(-45deg);
      display: inline-block;
      z-index: 0;
    }
  }

  .ng5-slider-pointer:after {
    display: none;
  }

  .ng5-slider-bubble {
    bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: $aqua-marine;
    border-radius: 4px;
    padding: 3px 2px !important;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 0;
    text-align: center;
    min-width: 40px;
    z-index: 4;
  }

  .ng5-slider-tick {
    width: 1px;
    height: 6px;
    margin-left: 7px;
    border-radius: 0;
    background: #979797;
    top: 20px;
  }
  .ng5-slider-span.ng5-slider-bubble.ng5-slider-model-high {

  }
  .ng5-slider-ticks-values-under .ng5-slider-tick-value {
    font-size: 10px;
    color: $main_gray_08;
    bottom: -22px;
  }
}