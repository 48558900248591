// // Common theme variables

// default colors
:root {
  --primary:  #323264;
  --secondary: #3e7da7;
  --tertiary:  #c3c3d1;
}

// sass map for theme
$theme: (
  --primary: var(--primary),
  --secondary: var(--secondary),
  --tertiary: var(--tertiary)
);

@function var($variable) {
  @return map-get($theme, $variable);
}

$main_lighter_blue:  var(--tertiary); //RGB 158  190  211
$main_light_blue:    var(--secondary); //RGB 62  125  167
$main_normal_blue:   var(--primary); //RGB 0  111  180

$main_light_gray:    #b7c6cd;

$main_gray_00: #fcfcfc;
$main_gray_01: #F5F5F0;
$main_gray_02: #f9fafb;
$main_gray_03: #ebeef0;
$main_gray_04: #d8dee2;
$main_gray_05: #d3d8e0;
$main_gray_06: #dfe7f2;
$main_gray_07: #a7b3bb;
$main_gray_08: #8c98a0;
//$main_gray_09: #4a4a4a;
$main_gray_09: #2F3339;
$main_gray_10: #41454e;
$main_gray_11: #0A1414;
$main_gray_12: #3e4557;
$walgreens_light_panel: #E1E1EC;
//$text_dark: #1e282d;
$text_dark: #323264;

$main_normal_red:    #e74c3c;
$main_light_red:     #e74c3c;
$walgreens_red:     #E62600;

$main_lighter_green: #84c0cc;
$main_light_green:   #2cc970;
$main_normal_green:  #3bb29b;
$secondary_light_green: #69d6c5;

$main_lavender: #9b59b6;

$bg-light-gray: #f7fafc;

// goals colors list

$g-1: #f5a623;
$g-2: #6ca4e6;
$g-3: #3A5A7A;
$g-4: #ec6b8a;
$g-5: #92b2ad;
$g-6: #685261;
$g-7: #92d1b4;
$g-8: #dcb047;
$g-9: #5a7d79;
$g-10: #9c5c76;
$g-11: #688072;
$g-12: #bf9297;
$g-13: #69855c;
$g-14: #3b3c5a;
$g-15: #4470af;
$g-16: #ce8543;
$g-17: #32667b;
$g-18: #ab3747;
$g-19: #098a7d;

// edit careplan modal
// 6 columns w/o progress and w/ edit
$task_list_grid: 17% 17% 17% 17% 17% 15% 1fr;
// 6 columns w/o progress and w/ edit
$task_list_grid_edit_subtask: 17% 17% 17% 17% 17% 15% 1fr;

// patient careplan view only
// 7 columns w/ progress and w/o edit
$task_list_grid_progress: 15% 15% 15% 15% 15% 15% 1fr;
// 7 columns w/o progress and w/ edit
$task_list_grid_subtask: 15% 15% 30% 15% 18% 1fr;

// careplan overview view only
// 6 columns w/o progress and edit
$task_list_grid_no_progress_no_edit: 20% 20% 20% 20% 15% 1fr;
// 6 columns w/o progress and edit
$task_list_grid_subtask_no_progress_no_edit: 0% 20% 40% 20% 20% 1fr;

// Patient Overview Widget styling
$widget-border-radius: 4px;
$widget-margin: 15px;
$widget-padding: 15px;
$widget-card-padding: 20px;

$b-shadow: 0 2px 4px 0 rgba(188, 199, 218, 0.5);


// responsive

$tbt-width: 1455px;

$min-tbt-width: 1456px;

$mobile-width: 960px;


// progress colors
$critical: $main_normal_red;
$poor: $g-1;
$neutral: #fecb2f;
$good: $main_normal_blue;
$excellent: $main_light_green;

$light-yellow: #fecb2f;

$light-blue: #00AEE9;

$inactive: #9ebed3;



// next Vol. colors
$light-blue-2: #f2faff;
$aqua-marine: #2cd9c5;



// New Color Theme #10451
$primary-color	    : #323264;
$heading-text	      : #2F3339;
$secondary-detail	  : #646464;
$section-background	: #EAEAF4;
$error-color		    : #E31836;
$border-color	      : #b3b3c9;
$text-contrast      : #575bc1;
$text-dark-1        : #4c4c4c;
