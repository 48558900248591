@import "cp-theme.scss";
.breadcrumbs-wrapper {
  margin: 0 auto;
  width: 100%;
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    li {
      padding-right: 20px;
      padding-left: 8px;
      position: relative;
      text-transform: uppercase;
      color: $primary-color;
      font-size: 12px;
      &:first-child {
        padding-left: 0px;
      }
      &:after {
        content: "";
        position: absolute;
        color: #000000;
        width: 5px;
        height: 5px;
        border: 1px solid #000;
        border-bottom: none;
        border-left: none;
        right: 5px;
        top: 3px;
        margin: auto 0px;
        transform: rotate(45deg);
      }
      &:last-child {
        padding-right: 0px;
        &:after {
          display: none;
        }
      }
      a {
        color: $secondary-detail;
      }
    }
    .active {
      a {
        color: $heading-text;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .breadcrumbs-wrapper {
    padding: 0 !important;
    ul {
      li {
        color: #b4b4ff;
        a {
          color: #fff;
        }
        &:after { border-color: #fff;}
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumbs-wrapper {
    display: none;
  }
}


// Button Style
.border-button-style {
  border: 1px solid $primary-color;
  color: $primary-color;
  &.mat-button-disabled {
    background: #eaeaea;
    color: #b5b5b5;
    border-color: #dcdcdc;
  }
}
.light-button-style {
  color: $primary-color;
  background: #e6f1ff;
  &.mat-button-disabled {
    background: #eaeaea;
    color: #b5b5b5;
    border-color: #dcdcdc;
  }
}
.dark-blue-button-style {
  background: $primary-color;
  color: #fff;
  &.mat-button-disabled {
    background: #eaeaea;
    color: #b5b5b5;
    border-color: #dcdcdc;
  }
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// White Box Area with Heading
.white-bg-box {
  background: #fff;
  border-radius: 10px;
  padding: 35px 47px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .white-bg-box {
    background: none;
    padding: 0;
    position: relative;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .white-bg-box {
    padding: 25px;
  }
}


// Header Section
.header-sec {
  mat-icon {
    color: $heading-text;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2.heading {
    color: $heading-text;
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: normal;
    .back-btn {
      border: none;
      padding: 0;
      min-width: auto;
      line-height: normal;
    }
  }
  h2.heading2 {
    color: $g-3;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  h2.heading3 {
    color: $heading-text;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    &.blue-color {
      color: $primary-color;
    }
  }
  input {
    color: #6d6d6d;
    border:1px solid $border-color;
    font-size: 14px;
    margin: 0;
    &:focus {
        border-width: 1px;
        border-color: $border-color;
    }
    &::-webkit-input-placeholder {
        color: #6d6d6d;
        font-weight: 400;
    }
    &:-ms-input-placeholder {
        color: #6d6d6d;
        font-weight: 400;
    }
    &::placeholder {
        color: #6d6d6d;
        font-weight: 400;
    }
  }
  .input-common, 
  .mat-input-element {
    width: 280px;
    padding: 0 10px;
  }
  h2 {
    mat-icon { color: $heading-text;}
  }
}
@media only screen and (min-width: 1680px){
  .header-sec {
    h2.heading {
      font-size: 28px;
      margin-bottom: 10px;
    }
    h2.heading2 {
      font-size: 18px;
      margin: 20px 0 !important;
    }
    h2.heading3 {
      font-size: 24px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .header-sec {
    h2.heading {
      font-size: 20px;
    }
    h2.heading2 {
      font-size: 15px;
    }
    h2.heading3 {
      font-size: 18px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .header-sec {
    h2.heading {
      color: #fff;
      font-size: 18px;
    }
    h2.heading2 {
      font-size: 14px;
    }
    h2.heading3 {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 767px){
  .header-sec {
      h2.heading {
          .back-btn button {
              padding: 10px 10px 10px 0px;
              margin-right: 10px;
          }
      }
    .border-button-style {background: #fff; color: $primary-color;}
  }
  .header-sec .mat-input-element {
    width: 100%;
  }
}

// pagination Design
.pagination-d {
  padding: 0;
  margin: 30px 0 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
  li {
      margin: 0 2px;
      width: 30px;
      height: 30px;
      a {
        display: flex;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        background: #d5e8ff;
        color: $primary-color;
        &.active {
            background: $primary-color;
            color: #fff;
        }
      }
      &.first-last {
        width: 80px;
        display: none;
      }
  }
}
@media only screen and (max-width: 991px) {
  .pagination-d {
      margin: 10px 0 0;
  }
}

.clinic_slot {
  .name_check {
    .mat-checkbox {
      font-size: 22px;
      color: $heading-text;
      position: relative;
      margin: 0;
      .mat-checkbox-inner-container {
        width: 19px;
        height: 19px;
        position: absolute;
        left: -35px;
        top: 4px;
      }
    }
  }
}