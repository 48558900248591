/* global variables for viewport */
$min-viewport-width: 1920;
$max-viewport-width: 2560;

@mixin scale-font($width) {
  font-size: $width;
}

/*
     1) Set a min-font-size 'min' when viewport width < min-viewport-width
     2) Set a max-font-size 'max' when viewport width > max-viewport-width
     3) linearly increase the font-size from min -> max
     between a viewport width of min-viewport-width -> max-viewport-width
*/
@mixin responsive-font($min, $max) {
  $responsive: -webkit-calc(#{$min}px + (#{$max} - #{$min}) * ((100vw - #{$min-viewport-width}px) / (#{$max-viewport-width} - #{$min-viewport-width})));

  @media (max-width: #{$min-viewport-width}px) {
    font-size: #{$min}px;
  }

  @media (min-width: #{$max-viewport-width}px) {
    font-size: #{$max}px;
  }

  font-size: $responsive;
}

.font_defaults {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.font_size_xs {
  @include responsive-font(10, 14);
  line-height: normal;
}

.font_size_sm {
  @include responsive-font(12, 16);
  line-height: normal;
}

.font_size_md {
  @include responsive-font(14, 19);
  line-height: normal;
}

.font_size_lg {
  @include responsive-font(16, 23);
}

.font_size_xl {
  @include responsive-font(18, 25);
}

.font_size_xxl {
  @include responsive-font(22, 31);
}

.fs-6 {
  font-size: 6px;
  &:before {
    font-size: 6px;
  }
}

.fs-8 {
  font-size: 8px !important;
  &:before {
    font-size: 8px;
  }
}

.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px;
  &:before {
    font-size: 10px;
  }
}
.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px !important;
  &:before {
    font-size: 12px !important;
  }
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px !important;
  &:before {
    font-size: 14px;
  }
}

.fs-15 {
  font-size: 15px;
  &:before {
    font-size: 15px;
  }
}

.fs-16 {
  font-size: 16px !important;
  &:before {
    font-size: 16px !important;
  }
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
  &:before {
    font-size: 20px;
  }
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px;
  &:before {
    font-size: 22px;
  }
}

.fs-24 {
  font-size: 24px !important;
  &:before {
    font-size: 24px !important;
  }
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px !important;
  &:before {
    font-size: 26px !important;
  }
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-38 {
  font-size: 38px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.t-up {
  text-transform: uppercase;
}


// font-weights

.fw-400 {
  font-weight: 400 !important;
}

.fw-500, .fw-600, .fw-700, .fw-900 {
  font-weight: 500 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.lh-sm {
  line-height: 16px;
}

.lh-1 {
  line-height: 1;
}
.lh-16px {
  line-height: 16px;
}
.lh-2 {
  line-height: 2rem;
}
